import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { ThreeDRotationSharp } from '@material-ui/icons';

class AddProductsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newProductKey:'',
            productName:'',
            productDesc:'',
            btwValue:'',
            price:0,
            status:'selected',
            image:[],
            images:[],
            uploading:false,
            uploaded:false,
            url:'',
            imageAvailable: false,
            activeVariations: false,
            cat:'selected',
            categories:[],
            catActive:false,
            tag:'selected',
            tags:[],
            tagActive:false,
            stock:true,
            clientUUID:'-ohlnfwebk',
            city:'',
            error:false,
            errorMessage:'Controller',
            variant:'selected',
            color:false,
            weight:false,
            quantity:false,
            measure:false,
            addVariantText:'',
            addVariantPrice:'',
            addVariantStock:0,
            variations:[],
            changeVariantText:'',
            changeVariantPrice:'',
            changeVariantStock:0,
            changes:false,
            category:'selected'
            
        };
    }

    getEmployees(){
    
        /*let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
                let employee = {resourceTitle: state.name, resourceId: state.resourceId};
                this.setState(prevState => ({
                    resourceMap: [...prevState.resourceMap, employee]
                }))  

        })});*/
    }


    addProduct(){
        if(this.state.productName != '' ){
            this.setState({
                changes:false
            })
        var key = Firebase.database().ref().child('/clients/-ljcbwenpidwdkenw/products/').push().key;
            Firebase.database().ref('/clients/-ljcbwenpidwdkenw/products/'+key).set({
                id: key,
      
                name: this.state.productName,
                price: Number(this.state.price),
                desc: this.state.productDesc,
                category: this.state.category,
                stock:this.state.stock,
                

        
            }).then((data)=>{
   

                toast.success('Product toegevoegd');
               
    
            }).then((data)=>{
this.setState({
                btwValue: 'selected',
                productName: '',
                price: 0,
                productDesc: '',
                status: '',
                stock: 0,
                activeVariations: false,
                variations: [],
                variant:'',
                tags:[],
                images:[],
                categories:[],
                category:''
       
             


            })
                
            }).then(()=>{
                this.setState({
                    changes: false
                })
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }else{
            this.setState({
                error: true,
                errorMessage:'Controlleer de productnaam.'
            })
        }
    }



    componentDidMount() {

    }

    handleaVariations(event){
console.log(event)
        if(this.state.activeVariations === true){
            this.setState({
                activeVariations: false
            })
        }else if(this.state.activeVariations === false){
            this.setState({
                activeVariations: true
            })
        }
        
    }

    handleChange(e){
        console.log(e.target.files[0])
        this.setState({
          image: e.target.files[0],
          errorMessageLogo:''
        })
      }

      handleUpload(){
          const uniqueId =this.makeid(12);
        const {image} = this.state;
        this.setState({
          uploaded:true
        })

        console.log(image)

        if(image != null){
            const uploadTask = Firebase.storage().ref(`images/${Firebase.auth().currentUser.uid}/${uniqueId}${image.name}`).put(image);
            uploadTask.on(
              'state_changed',
              snapshot => {
              },
              error => {
                console.log(error);
              },
              () => {
                Firebase.storage()
                  .ref('images/'+Firebase.auth().currentUser.uid)
                  .child(uniqueId+image.name)
                  .getDownloadURL()
                  .then(url => {
                    toast.success('Afbeelding opgeslagen');
                      
                    this.setState(prevState =>({
                      images: [...prevState.images,url],
                      imageAvailable:true,
                      uploading: true,
                      changes:true
                    }))
                  });
              }
            );
          }else{
          this.setState({
            errorMessageLogo: 'Er is geen foto geselecteert.',
            uploaded: false,
            uploading: false
          })
          
        }
    
        
      }

      makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

      addCat(event){
          this.setState(prevState => ({
            categories: [...prevState.categories, event],
            catActive: true,
            changes:true
          }))
      }

      addTag(){
        this.setState(prevState => ({
          tags: [...prevState.tags, this.state.tag],
          tagActive: true,
          changes:true
        }))
    }

    changeVariant(variant){
        console.log(variant);
        if(variant === 'Kleur'){
            this.setState({
                variant:variant,
                color:true,
                weight:false,
                quantity:false,
                measure:false
            })
        }else if(variant === 'Gewicht'){
            this.setState({
                variant:variant,
                color:false,
                weight:true,
                quantity:false,
                measure:false
            })
        }else if(variant === 'Hoeveelheid'){
            this.setState({
                variant:variant,
                color:false,
                weight:false,
                quantity:true,
                measure:false
            })
        }else if(variant === 'Maat'){
            this.setState({
                variant:variant,
                color:false,
                weight:false,
                quantity:false,
                measure:true
            })
        }
    }

    addVariation(){
        if(this.state.addVariantText !='' && this.state.addVariantPrice !='' && this.state.addVariantPrice !=0){
            let newVariation = {id: this.state.variations.length+1, name: this.state.addVariantText, price: this.state.addVariantPrice, stock:this.state.addVariantStock}
            this.setState(prevState => ({
                variations: [...prevState.variations, newVariation],
                addVariantText:'',
                addVariantPrice:'',
                addVariantStock:0,
                changes:true
            }))
        }else{
            toast.error('Niet alle velden zijn gevult probeer het opnieuw');
            this.setState({
                addVariantText:'',
                addVariantPrice:'',
                addVariantStock:0,
            })
        }

    }

  changeVariantProduct= async (item) =>{
      if(this.state.color === true){
       await this.setState({
            variations: this.state.variations.filter((object) => object.id !== item.id)
        })
       

                let changedVariant = {id: item.id,type:'Kleur', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
             await   this.setState(prevState => ({
                    variations: [...prevState.variations, changedVariant],
                    changeVariantText:'',
                    changeVariantPrice:'',
                    changeVariantStock:0,
                    changes:true
                }))
  
            }else  if(this.state.weight === true){
                await this.setState({
                    variations: this.state.variations.filter((object) => object.id !== item.id)
                })
                
               
        
                        let changedVariant = {id: item.id,type:'Gewicht', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
                     await   this.setState(prevState => ({
                            variations: [...prevState.variations, changedVariant],
                            changeVariantText:'',
                            changeVariantPrice:'',
                            changeVariantStock:0,
                            changes:true
                        }))
          

            }

           else if(this.state.quantity === true){
                await this.setState({
                    variations: this.state.variations.filter((object) => object.id !== item.id)
                })
               
        
                        let changedVariant = {id: item.id,type:'Hoeveelheid', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
                     await   this.setState(prevState => ({
                            variations: [...prevState.variations, changedVariant],
                            changeVariantText:'',
                            changeVariantPrice:'',
                            changeVariantStock:0,
                            changes:true
                        }))
          

            }

            else if(this.state.measure === true){
                await this.setState({
                    variations: this.state.variations.filter((object) => object.id !== item.id)
                })
               
        
                        let changedVariant = {id: item.id,type:'Maat', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
                     await   this.setState(prevState => ({
                            variations: [...prevState.variations, changedVariant],
                            changeVariantText:'',
                            changeVariantPrice:'',
                            changeVariantStock:0,
                            changes:true
                        }))
          

            }

            this.setState({
                changeVariantText:'',
                changeVariantPrice:'',
                changeVariantStock:0,
            })

    }

    deleteImage(url){
        this.setState({
            images: this.state.images.filter((item)=> item !== url),
        })
    }

    deleteCat(cat){

        this.setState({
            categories: this.state.categories.filter((item)=> item !== cat),
        })

        if(this.state.categories.length < 1){
            this.setState({
                catActive:false,
            })
        }

      
       /* Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/categories/'+cat).remove();

        if(this.state.categories.length < 1){
            this.setState({
                catActive:false
            })
        }

        toast.error('Categorie verwijderd')*/
    }


    

    render() {
        return (
            <div>
                <Toaster/>
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                    <Link to={{pathname: `/producten`}}>Terug</Link>
                    
                    <Button className="main-save-button" onClick={()=>this.addProduct()}>Voeg product toe</Button>
                   {this.state.error ? <span className="error-message-product">{this.state.errorMessage}</span>:null}
                    {/*<Link className="align-button-right">Product toevoegen</Link>*/}
                </Col>
                <Col sm={8} className="colum-left">
                    <Row>
                        <Col sm={12} className="add-product-colum">
                            <label>Productnaam</label><br/>
                            <input type="text" value="" value={this.state.productName} onChange={(event)=>this.setState({productName: event.target.value, error:false,changes:true})}/><br/><br/>
                            <label>Product omschrijving</label><br/>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.productDesc}
                                
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    this.setState({productDesc: data,changes:true})
                                } }

                            />
                        </Col>
                       {/*} <Col sm={12} className="add-product-colum">
                            <label>Media</label><br/>
                            <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleChange(event)} /><Button className="add-button-settings" onClick={()=>this.handleUpload()}>Voeg afbeelding toe</Button> <br/><br/>
                            <label>Geselecteerde product foto's</label>
                            {this.state.imageAvailable ?
                             <Row>
                                 {this.state.images.map((image)=>(
                                <Col sm={4} className="product-images">
                                <span className="delete-image button-list button-onimage" >
                                    <Button onClick={()=>this.deleteImage(image)}>Verwijder</Button>
                                </span>
                                <img src={image}/>
                            </Col>
                                ))}
                            </Row>
                            :
                            <Row>
                                <Col sm={12} className="nothing-present">
                                    <span>Geen afbeeldingen geselecteert</span>
                                </Col>
                            </Row>
                            }
                           
                        </Col>*/}
                        {this.state.activeVariations ?null:<Col sm={12} className="add-product-colum">
                            <label>Prijs per stuk incl. BTW</label><br/>
                            <span className="input-price">€ <input type="text" value={this.state.price} onChange={(event)=>this.setState({price: event.target.value,changes:true})}/> </span><br/><br/>
                            <label>Voorraad</label><br/>
                            <select value={this.state.stock} onChange={(event)=>this.setState({stock: event.target.value,changes:true})}>
                            <option selected="selected" disabled="disabled">Is het product voorradig?</option>
                                <option value={true}>Ja</option>
                                <option value={false}>Nee</option>
                            </select><br/><br/>
                            <label>BTW-percentage</label><br/>
                            <select value={this.state.btwValue} onChange={(event)=>this.setState({btwValue: event.target.value,changes:true})}>
                            <option selected="selected" disabled="disabled">Selecteer een BTW percentage</option>
                                <option value="0">0%</option>
                                <option value="6">6%</option>
                                <option value="9">9%</option>
                                <option value="21">21%</option>
                            </select>
                        </Col>}
                        
                        <Col sm={12} className="last-col add-product-colum">
                        < hr/>
                        <Button onClick={()=>this.addProduct()}>Voeg product toe</Button>
                        </Col>

                    </Row>
                </Col>
            
                <Col sm={4}>
                    <Row>

                        <Col sm={12} className="add-product-colum right-product-add-colom categories-products">
                        <label>Categorieën</label><br/>
                            <select value={this.state.category} onChange={(event)=> this.setState({category:event.target.value})}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een categorie</option>
                            <option value="Soepen">Soepen</option>
                                <option value="Pokebowls">Pokebowls</option>
                                <option value="Voorgerechten">Voorgerechten</option>
                                <option value="Hoofdgerechten">Hoofdgerechten</option>
                                <option value="Desserts">Desserts</option>
                            </select><br/>
                   
                            <br/>

                            <label>Geselecteerde categorieën</label><br/>
                            <ul className="button-list">
                               
                               <li>{this.state.category}  <Button   onClick={()=>this.deleteCat()}>Verwijder</Button></li>
                          
                          
                           
                       </ul>
                            
                        </Col>
                        {/*<Col sm={12} className="add-product-colum right-product-add-colom categories-products">
                            <label>Tags</label><br/>
                            <select value={this.state.tag} onChange={(event)=>this.setState({tag: event.target.value})}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een tag</option>
                                <option value="Koffie">Koffie</option>
                                <option value="Thee">Thee</option>
                                <option value="Frisdrank">Frisdrank</option>
                                <option value="Warm">Warme dranken</option>
                                <option value="Koud">Koude maaltijd</option>
                                <option value="Warme">Warme Maaltijd</option>
                                <option value="Dames Kleding">Dames kleding</option>
                                <option value="Heren Kleding">Heren kleding</option>
                                <option value="Kinder Kleding">Kinder kleding</option>
                                <option value="Heren Kleding">Heren Sieraden</option>
                                <option value="Dames Kleding">Dames Sieraden</option>
                                <option value="Kinder Kleding">Kinder kleding</option>
                               
                            </select><br/>
                            <Button onClick={()=>this.addTag()}>Maak tags aan</Button><br/><br/>
                            <br/>

                            <label>Geselecteerde tags</label><br/>
                            {this.state.tagActive?
                            <ul>
                                {this.state.tags.map((item)=>(
                                    <li>{item}</li>
                                ))}
                               
                                
                            </ul>
                            :<span>Geen tags geselecteert</span>}
                            
                                </Col>*/}
                    </Row>
                </Col>
   
            </Row>
            {this.state.changes?
            <Row className="changed-row">
                <Col sm={12} className="changed-col">
                    <p>Er zijn wijzigingen aangebracht. Vul alle velden en vergeet dan niet om het product toe te voegen.</p>
                </Col>
            </Row>:null}
            </div>



        );
    }
};

export default AddProductsComponent;