import React from "react";
import { Container, Row, Col } from "shards-react";

import FooterComponent from "../../components/Footer/FooterComponent";
import CheckPaymentComponent from "../../components/frontend/CheckPaymentComponent";
import ThankYouComponent from "../../components/frontend/ThankYouComponent";
import HeaderComponent from "../../components/Header/headerComponent";



const ThankYouView = (props) => (
    <div>
    <HeaderComponent/>
    <ThankYouComponent  />
    <FooterComponent/>
    
    </div>
);

export default ThankYouView;
