import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';

import { Container } from "shards-react";

import { Link } from 'react-router-dom';




class ThankYouComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {


        };
    }


items=[];
    componentDidMount(){

        




      /* setTimeout(() => {  
this.sendInvoice();
        }, 1000);*/
    
      
    }




    

    

    render() {
        return (
           

                    

                <Col sm={12}>


                    <Container>
                    
                    <Row  >
                        <Col sm={6} className="thank-you-detail left-colum-thank-you">
                            <h2>BEDANKT VOOR UW INSCHRIJVING.</h2>
                            <h4>We zien je graag terug.</h4>

                            <img src={require('../../assets/images/svg/thank-you-person.svg')} />


                        </Col>
                        <Col sm={6} className="thank-you-detail right-title">
                            <h2>HOE NU VERDER?</h2>
                            <div className="thank-you-block">
                                <h3 className="firstblock-thankyou">Je ontvangt een bevestiging.</h3>
                                <p>Binnen enkele ogenblikken sturen wij jou een bevestiging op: {localStorage.getItem('email')}<br/>Indien je geen mail ontvangt, check dan je spam-map en voeg het mail-adres toe aan je contactenlijst!</p>
                            </div>
                            <div className="thank-you-block">
                                <h3 className="secondblock-thankyou">Je inschrijving wordt bekeken.</h3>
                                <p>Wij controleren iedere inschrijving of deze past binnen onze eisen.</p>
                            </div>
                            <div className="thank-you-block">
                                <h3 className="thirdblock-thankyou">Je ontvangt een antwoord.</h3>
                                <p>Zodra wij jouw inschrijving hebben beoordeeld krijg je van ons een antwoord!</p>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                </Col>
               
               
                
                    
   

            
       



        );
    }
};

export default ThankYouComponent;