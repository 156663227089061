

export default function() {
  return [
    /*  {
      title: "Bestellingen",
      to: "/bestellingen",
      htmlBefore: 'shopping-cart',
      htmlAfter: ""
    }, 
    {
      title: "Producten",
      to: "/producten",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/systeem-foto%2Flabel.svg?alt=media&token=94da3c67-cc4f-48bc-8e83-181c2d1369f2',
      htmlAfter: ""
    }, 
    {
      title: "Mijn profiel",
      to: "/profiel",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/systeem-foto%2Fuser.svg?alt=media&token=392e2cd0-7a58-4272-8390-2ab56a59fd15',
      htmlAfter: ""
    },
    {
      title: "Wijzigingen",
      to: "/aanmeldingen",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/systeem-foto%2Fbullhorn-solid.svg?alt=media&token=8ac98d40-2050-447c-8cd6-fd4494702e79',
      htmlAfter: ""
    }, 
    {
      title: "Gids downloads",
      to: "/downloads",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/systeem-foto%2Fdownload-solid.svg?alt=media&token=a723caa9-e04c-4f0b-aedf-c56b3a8a880a',
      htmlAfter: ""
    },*/      
    {
      title: "Inschrijvingen jaarmarkt Beek",
      to: "/inschrijvingen",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/list-solid.svg?alt=media&token=86036c72-148c-4ca7-835e-b63b453c9951',
      htmlAfter: ""
    },
    {
      title: "Inschrijvingen Beeker ondernemer",
      to: "/inschrijvingen-beekerondernemer",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/list-solid.svg?alt=media&token=86036c72-148c-4ca7-835e-b63b453c9951',
      htmlAfter: ""
    },
    {
      title: "Inschrijvingen Baek Voiture",
      to: "/inschrijvingen-baekvoiture",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/list-solid.svg?alt=media&token=86036c72-148c-4ca7-835e-b63b453c9951',
      htmlAfter: ""
    }
  ];
}
