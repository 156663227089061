import React from "react";
import { Container, Row, Col } from "shards-react";

import FooterComponent from "../../components/Footer/FooterComponent";
import CheckPaymentComponent from "../../components/frontend/CheckPaymentComponent";
import HeaderComponent from "../../components/Header/headerComponent";



const CheckPaymentView = ({match, location}) => (
    <div>
    <HeaderComponent/>
    <CheckPaymentComponent  id={location.search}/>
    <FooterComponent/>
    
    </div>
);

export default CheckPaymentView;
