import React from "react";
import { Container, Row, Col } from "shards-react";
import AdminPanelIndex from "../../components/adminpanel";

import PageTitle from "../../components/common/PageTitle";
import FooterComponent from "../../components/Footer/FooterComponent";
import FrontendHomeComponent from "../../components/frontend";
import BaekvoitureComponent from "../../components/frontend/baekVoiture";
import HeaderComponent from "../../components/Header/headerComponent";
import ProductsIndex from "../../components/products";



const BaekvoitureView = () => (
    <div>
    <HeaderComponent/>
    <BaekvoitureComponent />
    <FooterComponent/>
    
    </div>
);

export default BaekvoitureView;
