import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, string } from "shards-react";
import Firebase from 'firebase';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { Container } from "shards-react";

import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';


class CheckPaymentComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect:false,
            errorRedirect:false,
            products:[]

        };
    }

    splittedItem ='';
    
componentDidMount(){
    this.splittedItem = this.props.id.split("?");
    this.items = JSON.parse(localStorage.getItem('shoppingBag'));
    for(let i = 0; i <this.items.length; i++){
        let products = Firebase.database().ref('/clients/-ljcbwenpidwdkenw/products/'+this.items[i].id);
        products.on('value' , snapshot => {
        let state = snapshot.val();
   
        let newProduct = {id: state.id, price: state.price, name: state.name, amount: this.items[i].amount,sideDish:this.items[i].sideDish, uniqueId: this.items[i].uniqueId}
        this.setState(prevState =>({
            products: [...prevState.products, newProduct]
        }))
    
 
    })

     
    }

    setTimeout(() => {  
        this.checkPayment();
    }, 6000);
}
items=[];
checkPayment(){
    this.splittedItem = this.props.id.split("?");

    console.log(this.splittedItem[1].split("id=")[1])
   let ref = Firebase.database().ref('/clients/-ljcbwenpidwdkenw/payments/'+this.splittedItem[1].split("id=")[1]);
    ref.on('value' , snapshot => {
        let state = snapshot.val();
  
        if(state.status === 'paid'){
            this.setState({
                redirect:true,
            })
 
        }else{
 
         this.setState({
             errorRedirect:true
         })
        }
   
 
    })
}
    

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/bedankt?id='+this.splittedItem[1].split("id=")[1]} />
        }else if(this.state.errorRedirect){
            return <Redirect to={'/afrekenen'} />
        }
        else{
        return (
           

                    

                <Col sm={12}>
                    <Container>
                        <Row>
                            <Col sm={12} className="check-payment-col">
                            <h1>Controleren betaling<br/> Sluit het scherm niet!</h1>
                            <Loader type="Oval" color="#000" height={50} width={100} /*timeout={3000}*/  />
                            </Col>
                        </Row>
                  

                        
                    </Container>
                </Col>
               
               
                
                    
   

            
       



        );
    }}
};

export default CheckPaymentComponent;