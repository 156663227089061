import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button,Container } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { Redirect } from 'react-router-dom';

const notify = () => toast('Je wijzigingen zijn opgeslagen!');

class ReviewChanges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            meatActive:true,
            fishActive:false,
            milkActive:false,
            drinksActive:false,
            breadActive:false,
            patatoActive:false,
            dryActive:false,
            iceActive:false,
            item:[],
            imagesPrimaire:[],
            companyImage:[],
            secundareImages:[],
            changeData:false,
            companyName: '',
            companyAdres: '',
            companyPostal: '',
            companyContact:'',
            companyFunction: '',
            companyPhone: '',
            companyMail: '',
            companySite: '',
            mvo: '',
            desc:'',
            product:'',
            price:'',
            period:'',
            employees:'',
            promo: '',
            order: '',
            delivery:'',
            extraInfo:'',
            saving:false,
            overlay:false,
            imagesCompanyLogo:'',
            imagesCompany:'',
            imageAvailableCompany:false,
            imagesCompanyName:'',
            loadingComapny:false,
            handleUpload:false,
            overlayPrimaire:false,
            imagePrimaireSet: '',
            imagesPrimaireName:'',
            loadingPrimaire:true,
            imagesSecundaireNames:[],
            imagesSecundaireTwo:[],
            redirect:false
        };
    }

    getUrl(company,name,id){


        Firebase.storage().ref('/Streekproductengids/images/'+company+'/'+name).getDownloadURL().then((url) => {
     
                
    
                let newObject ={id: id,url:url}
    
                this.setState(prevState => ({
                  
                    imagesPrimaire: newObject
    
                }));
          
          })
    
    
    }

    getCompanyImage(company,name,id){


        Firebase.storage().ref('/Streekproductengids/images/'+company+'/'+name).getDownloadURL().then((url) => {
     
                
    
                let newObject ={id: id,url:url}
    
                this.setState(prevState => ({
                  
                    companyImage :newObject
    
                }));
          
          })
    
    
    }

    getUrlSecundaireOne(company,id){
        let ref = Firebase.database().ref('/streekproducten/'+id+'/secundaireImages/');
        ref.once('value' , snapshot => {
           this.setState({secundareImages:[]})
    
           snapshot.val();
            snapshot.forEach((childSnap) => {
                let stateFac = childSnap.val();
    
                if(stateFac.name){
                let imageName = stateFac.name.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2');
                Firebase.storage().ref('/Streekproductengids/images/'+company+'/'+imageName).getDownloadURL().then((url) => {
     
            
    
                    let newObject ={id: id,url:url,imageId: stateFac.id}
                    console.log(url)
                    this.setState(prevState => ({
                      
                        secundareImages: [...prevState.secundareImages, newObject]
        
                    }));
              
              })}else{
                  console.log('no-image')
              }
               
    
            })
        });
    }

    loadData(){
        const query = new URLSearchParams(this.props.id);
this.setState({})

        let items = Firebase.database().ref('/streekproducten-temp/'+this.props.data.data.id);
        items.on('value' , snapshot => {
      
            let state = snapshot.val();
            if(state){
        
            this.getUrl(state.companyName,state.imagesPrimaireName.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2'),state.id)
            this.getUrlSecundaireOne(state.companyName,state.id);
            this.getCompanyImage(state.companyName,state.imagesCompanyName.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2'),state.id);
            this.setState({
                id:state.id,
                item: state,
                companyName: state.companyName,
                companyAdres: state.companyAdres,
                companyPostal: state.companyPostal,
                companyContact:state.companyContact,
                companyFunction: state.companyFunction,
                companyPhone: state.companyPhone,
                companyMail: state.companyMail,
                companySite: state.companySite,
                mvo: state.mvo,
                desc:state.desc,
                product:state.product,
                price:state.price,
                period:state.period,
                employees: state.employees,
                promo: state.promo,
                order: state.order,
                delivery:state.delivery,
                category:state.category,
                extraInfo:state.extraInfo,
                imagesCompanyName:state.imagesCompanyName,
                imagesPrimaireName:state.imagesPrimaireName,
                loadingComapny:false,
                loadingPrimaire:false,
                handleUpload:false,
            })}
        })
    }

    componentWillMount(){
        window.scrollTo(0, 0)
     this.loadData();
       console.log(this.props.data.data)
        
    }

    updateData(){
        this.setState({
            saving:true
        })

        Firebase.database().ref('/streekproducten/'+this.props.data.data.id).update({
            id:this.state.id,
            companyName: this.state.companyName,
                companyAdres: this.state.companyAdres,
                companyPostal: this.state.companyPostal,
                companyContact: this.state.companyContact,
                companyFunction: this.state.companyFunction,
                companyPhone: this.state.companyPhone,
                companyMail: this.state.companyMail,
                companySite: this.state.companySite,
                mvo: this.state.mvo,
                desc: this.state.desc,
                product: this.state.product,
                price: this.state.price,
                period: this.state.period,
                employees: this.state.employees,
                promo: this.state.promo,
                order: this.state.order,
                delivery: this.state.delivery,
                extraInfo: this.state.extraInfo,
                imagesCompanyName:this.state.imagesCompanyName,
                imagesPrimaireName:this.state.imagesPrimaireName,
                category:this.state.category
        }).then(()=>{
            const query = new URLSearchParams(this.props.id);
           // Firebase.database().ref('getUrlSecundaireOne').remove();
            for(let m = 0; m< this.state.imagesSecundaireNames.length; m++){
                var keyImg= Firebase.database().ref().child('/streekproducten/'+this.props.data.data.id+'/secundaireImages').push().key;
                Firebase.database().ref('/streekproducten/'+this.props.data.data.id+'/secundaireImages/'+keyImg).set({

                    id: keyImg,
                    name: this.state.imagesSecundaireNames[m]
                }).then((data)=>{
                
        
                }).catch((error)=>{
                    //error callback
                    console.log('error ' , error)
                })
            }
        }).then((data)=>{
            this.setState({
                redirect:true
               })
            Firebase.database().ref('/streekproducten-temp/'+this.props.data.data.id).remove();
        
        })
    }

    handleChangeCompanyLogo(e){
 
        this.setState({
         imageCompanyLogo: e.target.files[0],
          errorMessageLogo:''
        })
    
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }


    handleUploadCompanyLogo(e){

        let logoFile = e.target.files[0];
        const uniqueId =this.makeid(12);
      const {imageCompanyLogo} = this.state;
      this.setState({
        uploaded:true,
        loadingComapny:true,
        handleUpload:true
      })
      const query = new URLSearchParams(this.props.id);


      if(logoFile != null){
          const uploadTask = Firebase.storage().ref(`Streekproductengids/images/${this.state.companyName}/${uniqueId}${logoFile.name}`).put(logoFile);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('Streekproductengids/images/'+this.state.companyName+'/')
                .child(uniqueId+logoFile.name)
                .getDownloadURL()
                .then(url => {
                  toast.success('Afbeelding opgeslagen');
                    
                  this.setState(prevState =>({
                    imagesCompany:url,
                    imagesCompanyLogo:uniqueId+logoFile.name,
                    imageAvailableCompany:true,
                    uploading: true,
                    changes:true,
                    showuploadlog:false,
                   
                  }))
                }).then(()=>{
                      this.setState({ overlay:false})
                        this.updateData();
                     

                }).then(()=>{
                    console.log(this.state.companyName)
                    this.setState({
                        loadingComapny:true
                    })

                    setTimeout(() => { this.loadData();  }, 6000);
                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }

    handleUploadCompanyPrimaire(e){

        let logoFile = e.target.files[0];
        const uniqueId =this.makeid(12);
      const {imageCompanyLogo} = this.state;
      this.setState({
        uploaded:true,
        loadingComapny:true,
        handleUpload:true
      })
      const query = new URLSearchParams(this.props.id);


      if(logoFile != null){
          const uploadTask = Firebase.storage().ref(`Streekproductengids/images/${this.state.companyName}/${uniqueId}${logoFile.name}`).put(logoFile);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('Streekproductengids/images/'+this.state.companyName+'/')
                .child(uniqueId+logoFile.name)
                .getDownloadURL()
                .then(url => {
                  toast.success('Afbeelding opgeslagen');
                    
                  this.setState(prevState =>({
                    imagePrimaireSet:url,
                    imagesPrimaireName:uniqueId+logoFile.name,
                    imageAvailableCompany:true,
                    uploading: true,
                    changes:true,
                    showuploadlog:false,
                   
                  }))
                }).then(()=>{
                      this.setState({ overlayPrimaire:false})
                        this.updateData();
                     

                }).then(()=>{
                    console.log(this.state.companyName)
                    this.setState({
                        loadingPrimaire:true,
                    })

                    setTimeout(() => { this.loadData();  }, 6000);
                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }

    handleUploadSecundaire(e){

        let logoFile = e.target.files[0];
        const uniqueId =this.makeid(12);
      const {imageCompanyLogo} = this.state;
      this.setState({
        uploaded:true,
        loadingComapny:true,
        handleUpload:true
      })
      const query = new URLSearchParams(this.props.id);
    
    
      if(logoFile != null){
          const uploadTask = Firebase.storage().ref(`Streekproductengids/images/${this.state.companyName}/${uniqueId}${logoFile.name}`).put(logoFile);
          uploadTask.on(
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
              Firebase.storage()
                .ref('Streekproductengids/images/'+this.state.companyName+'/')
                .child(uniqueId+logoFile.name)
                .getDownloadURL()
                .then(url => {
                  toast.success('Afbeelding opgeslagen');
    
                  
                    
                  this.setState(prevState =>({
                    imagesSecundaireTwo: [...prevState.imagesSecundaireTwo,url],
                    imagesSecundaireNames:[...prevState.imagesSecundaireNames,uniqueId+logoFile.name],
                    imageAvailableCompany:true,
                    uploading: true,
                    changes:true,
                    showuploadlog:false,
                   
                  }))
                }).then(()=>{
                     
                        this.uploadSecundaire();
                     
    
                }).then(()=>{
                    console.log(this.state.companyName)
                    this.setState({
                        loadingPrimaire:true,
                    })
    
                    setTimeout(() => { this.loadData();  }, 8000);
                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
    
      
    }
    
    uploadSecundaire(){
        const query = new URLSearchParams(this.props.id);
        for(let m = 0; m< this.state.imagesSecundaireNames.length; m++){
            var keyImg= Firebase.database().ref().child('/streekproducten/'+query.get('id')+'/secundaireImages').push().key;
            Firebase.database().ref('/streekproducten/'+query.get('id')+'/secundaireImages/'+keyImg).set({
    
                id: keyImg,
                name: this.state.imagesSecundaireNames[m],
              order: (Number(this.state.secundareImages.length)+1)
            }).then((data)=>{
                this.setState({
                    imagesSecundaireNames:[]
                })
            
    
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }
    }

    deleteImageSecundaires(url){
        const query = new URLSearchParams(this.props.id);
        console.log(url)

        Firebase.database().ref('/streekproducten/'+query.get('id')+'/secundaireImages/'+url.imageId).remove().then(()=>{
            this.loadData();
        });


    }

   

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/aanmeldingen'} />
          }else{

       
          
        return (
            <div>
         
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                    <Link to={{pathname: `/aanmeldingen`}}>Terug</Link>
                    <Button className="main-save-button" onClick={()=>this.updateData()}>Goedkeuren</Button>
                    {/*<Link className="align-button-right">Product toevoegen</Link>*/}
                </Col>
                <Col sm={12} className="colum-left">
                <Container className="participant-details-container">

                <Row className="participant-details-row">
                <Col sm={12} >
                <img src={this.state.imagesPrimaire.url} />
                <Button className="change-primaire-logo" onClick={()=>this.setState({overlayPrimaire:true})}><i class="fas fa-edit"></i></Button>
                {this.state.companyImage.url?<span className="logo-company"> <img src={this.state.companyImage.url} /></span>:null}
                <Button className="change-logo" onClick={()=>this.setState({overlay:true})}><i class="fas fa-edit"></i></Button>
                </Col>
            </Row>
                <Row>

                    <Col sm={12} className="recept-getting-ready green-title">
                    <h2>{this.state.item.companyName}</h2>
                    <ul>
                    <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/bedrijfsnaam.png')} /> <span className="title-participants-list">Naam van het bedrijf</span><span className="desc-participants-list"><input type="text" value={this.state.companyName} onChange={(event)=>this.setState({companyName: event.target.value})}/></span></li>
                    <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/adrespostcode.png')} /> <span className="title-participants-list">Adres van bedrijf</span><span className="desc-participants-list"><input type="text" value={this.state.companyAdres} onChange={(event)=>this.setState({companyAdres: event.target.value})}/></span></li>
                    <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/postcode.png')} /> <span className="title-participants-list">Postcode en plaats</span><span className="desc-participants-list"><input type="text" value={this.state.companyPostal} onChange={(event)=>this.setState({companyPostal: event.target.value})}/></span></li>
                    <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/contactpersoon.png')} /> <span className="title-participants-list">Contactpersoon</span><span className="desc-participants-list"><input type="text" value={this.state.companyContact} onChange={(event)=>this.setState({companyContact: event.target.value})}/></span></li>
                    <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/functie.png')} /> <span className="title-participants-list">Functie</span><span className="desc-participants-list"><input type="text"  type="text" value={this.state.companyFunction} onChange={(event)=>this.setState({companyFunction: event.target.value})}/></span></li>
                    <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/telefoon.png')} /> <span className="title-participants-list">Telefoon</span><span className="desc-participants-list"><input type="text"  type="text" value={this.state.companyPhone} onChange={(event)=>this.setState({companyPhone: event.target.value})}/></span></li>
                    <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/email.png')} /> <span className="title-participants-list">E-mail</span><span className="desc-participants-list"><input type="text"  value={this.state.companyMail} onChange={(event)=>this.setState({companyMail: event.target.value})}/></span></li>
                    <li className="participant-list"><img src={require('../../assets/images/streekproductengids/icons/email.png')} /> <span className="title-participants-list">Website</span><span className="desc-participants-list"><input type="text" value={this.state.companySite} onChange={(event)=>this.setState({companySite: event.target.value})}/></span></li>
                       
                    {this.state.item.companyFb  || this.state.item.companyLi || this.state.item.companyIg ? <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/socialmedia.png')} /> <span className="title-participants-list">Social Media</span><span className="desc-participants-list">{this.state.item.companyFb}{this.state.item.companyFb && this.state.item.companyIg ? ', '+this.state.item.companyIg : this.state.item.companyIg} {this.state.item.companyFb && this.state.item.companyLi || this.state.item.companyIg && this.state.item.companyLi ? ', '+this.state.item.companyLi : this.state.item.companyLi}</span></li>:null}
                    <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/mvoduurzaamheid.png')} /> <span className="title-participants-list">MVO / Duurzaamheid</span><span className="desc-participants-list"><CKEditor editor={ ClassicEditor } data={this.state.mvo} onChange={ ( event, editor ) => {const data = editor.getData();this.setState({mvo: data,changes:true})} }/></span></li>
                    <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/beschrijving.png')} /> <span className="title-participants-list">Het bedrijf</span><span className="desc-participants-list"><CKEditor editor={ ClassicEditor } data={this.state.desc} onChange={ ( event, editor ) => {const data = editor.getData();this.setState({desc: data,changes:true})} }/></span></li>
                    <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/product.png')} /> <span className="title-participants-list">Product</span><span className="desc-participants-list"><CKEditor editor={ ClassicEditor } data={this.state.product} onChange={ ( event, editor ) => {const data = editor.getData();this.setState({product: data,changes:true})} }/></span></li>
                    <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/prijs.png')} /> <span className="title-participants-list">Prijs</span><span className="desc-participants-list"><input type="text" value={this.state.price} onChange={(event)=>this.setState({price: event.target.value})}/></span></li>
                    <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/periode.png')} /> <span className="title-participants-list">Periode</span><span className="desc-participants-list"><input type="text" value={this.state.period} onChange={(event)=>this.setState({period: event.target.value})}/></span></li>
                    <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/personeel.png')} /> <span className="title-participants-list">Personeel</span><span className="desc-participants-list"><input type="text" value={this.state.employees} onChange={(event)=>this.setState({employees: event.target.value})}/></span></li>
                    <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/promotie.png')} /> <span className="title-participants-list">Promotie</span><span className="desc-participants-list"><input type="text" value={this.state.promo} onChange={(event)=>this.setState({promo: event.target.value})}/></span></li>
                    <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/bestellen.png')} /> <span className="title-participants-list">Bestellen</span><span className="desc-participants-list"><input type="text" value={this.state.order} onChange={(event)=>this.setState({order: event.target.value})}/></span></li>
                    <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/levering.png')} /> <span className="title-participants-list">Levering</span><span className="desc-participants-list"><input type="text" value={this.state.delivery} onChange={(event)=>this.setState({delivery: event.target.value})}/></span></li>
                    <li className="participant-list participant-list-full"><img src={require('../../assets/images/streekproductengids/icons/extrainfo.png')} /> <span className="title-participants-list">Extra informatie</span><span className="desc-participants-list"><input type="text" value={this.state.extraInfo} onChange={(event)=>this.setState({extraInfo: event.target.value})}/></span></li>
                    </ul>
                    </Col>
                    <Col sm={6}>
                    {this.state.secundareImages.length === 2 ? <input disabled type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleUploadCompanyPrimaire(event)} />:<input  type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleUploadCompanyPrimaire(event)} />}

                        <br/><br/>
                    </Col>
                    <Col sm={6}  className="change-secundaire">
                        {this.state.secundareImages.length === 2 ? 
                        <p className="color-red"> Je hebt {this.state.secundareImages.length} van de 2 posities in gebruik, verwijder een foto om een nieuwe toe te voegen.</p>:
                        <p className="color-green"> Je hebt {this.state.secundareImages.length} van de 2 posities in gebruik, voeg een foto toe!</p>        
                    }
                       
                    </Col>
                    <Col sm={6} className="participant-secundaire-image">
                        {this.state.secundareImages.slice(0,1).map((iamge)=>(
                            <span>
                              <span className="delete-image button-list button-onimage" >
                              <Button onClick={()=>this.deleteImageSecundaires(iamge)}>Verwijder</Button>
                          </span>
                            <img src={iamge.url} />
                            </span>
                        ))}
                
                    </Col>
                    <Col sm={6} className="participant-secundaire-image">
                        {this.state.secundareImages.slice(1,2).map((iamge)=>(
                             <span>
                             <span className="delete-image button-list button-onimage" >
                             <Button onClick={()=>this.deleteImageSecundaires(iamge)}>Verwijder</Button>
                         </span>
                            <img src={iamge.url} />
                            </span>
                        ))}
                
                    </Col>

                </Row>
            </Container>
                </Col>
            
               
   
            </Row>
            {this.state.changes?
            <Row className="changed-row">
                <Col sm={12} className="changed-col">
                    <p>Er zijn wijzigingen aangebracht. Als de wijzigingen akkoord zijn bevestig deze dan.</p>
                    <Button  className="changed-button" onClick={()=>this.updateData()}>Goedkeuren</Button>
                </Col>
            </Row>:null}
            </div>



        );
    }
}
};

export default ReviewChanges;