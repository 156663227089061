import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { Redirect } from 'react-router-dom';

class ParticipantIndexPostal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meatActive:false,
            fishActive:false,
            milkActive:false,
            drinksActive:false,
            breadActive:false,
            patatoActive:false,
            dryActive:false,
            iceActive:false,
            items:[],
            images:[],
            filterItem:'',
            redirect:false
        };
    }

    deleteFilter(){
        console.log('here')
        localStorage.setItem('postalSet',false)
        localStorage.setItem('postal','')
        this.setState({
            redirect:true
        })
    }

    componentDidMount(){
        console.log(this.props.data.data)
       if(this.props.data){
           this.setState({
               filterItem:this.props.data
           })
           if(this.props.data === 'Vlees, wild gevogelte'){
            this.setState({
             meatActive:true,
             fishActive:false,
             milkActive:false,
             drinksActive:false,
             breadActive:false,
             patatoActive:false,
             dryActive:false,
             iceActive:false,
            })
            }
           else if(this.props.data === 'Vis'){
               this.setState({
                meatActive:false,
                fishActive:true,
                milkActive:false,
                drinksActive:false,
                breadActive:false,
                patatoActive:false,
                dryActive:false,
                iceActive:false,
               })
           }
           else if(this.props.data === 'Zuivel, kaas & eieren'){
            this.setState({
             meatActive:false,
             fishActive:false,
             milkActive:true,
             drinksActive:false,
             breadActive:false,
             patatoActive:false,
             dryActive:false,
             iceActive:false,
            })
            }
            else if(this.props.data === 'Dranken'){
                this.setState({
                 meatActive:false,
                 fishActive:false,
                 milkActive:false,
                 drinksActive:true,
                 breadActive:false,
                 patatoActive:false,
                 dryActive:false,
                 iceActive:false,
                })
            }
            else if(this.props.data === 'Brood & Banket'){
                this.setState({
                 meatActive:false,
                 fishActive:false,
                 milkActive:false,
                 drinksActive:false,
                 breadActive:true,
                 patatoActive:false,
                 dryActive:false,
                 iceActive:false,
                })
            }
            else if(this.props.data === 'Aardappelen, Groente, fruit'){
                this.setState({
                 meatActive:false,
                 fishActive:false,
                 milkActive:false,
                 drinksActive:false,
                 breadActive:false,
                 patatoActive:true,
                 dryActive:false,
                 iceActive:false,
                })
            }
            else if(this.props.data === 'Droogwaren'){
                this.setState({
                 meatActive:false,
                 fishActive:false,
                 milkActive:false,
                 drinksActive:false,
                 breadActive:false,
                 patatoActive:false,
                 dryActive:true,
                 iceActive:false,
                })
            }
            else if(this.props.data === 'Ijs'){
                this.setState({
                 meatActive:false,
                 fishActive:false,
                 milkActive:false,
                 drinksActive:false,
                 breadActive:false,
                 patatoActive:false,
                 dryActive:false,
                 iceActive:true,
                })
            }
       }
        window.scrollTo(0, 0)
        let items = Firebase.database().ref('/streekproducten/');
        items.on('value' , snapshot => {

            snapshot.forEach((childSnap) => {
                let newShop = childSnap.val();  
            
                this.setState(prevState =>({
                    items: [...prevState.items, newShop]
                }))
            })
        })

        let images = Firebase.database().ref('/streekproducten/');
        images.on('value' , snapshot => {

            snapshot.forEach((childSnap, i) => {
                let newShop = childSnap.val();  

                /*const BASE_URL = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
                const APIKey= '+CA&key=AIzaSyB7G-vcpfhNmIdFl-RkodKDYsDY1VTQZV0'
                fetch(`${BASE_URL}${newShop.companyAdres}${newShop.companyPostal}${APIKey}`, { 
                  method: 'GET',
                  headers:{
                   
                  }
                })
                .then(response => response.json())
                .then(json => {
                    if(json.results[0]){
                        Firebase.database().ref('/streekproducten/'+newShop.id).update({

                            lat: json.results[0].geometry.location.lat,
                            lng:json.results[0].geometry.location.lng, 
                           
                            
                        
            
                    
                        }).then((data)=>{
       
                console.log('done')
                        }).catch((error)=>{
                            console.log('not done')
                        })
                    }else{
                        console.log('not')
                    }
                   // console.log(newShop.id +'aantal:'+ i)
                  
                
                 
                
                });*/

                Firebase.storage().ref('/Streekproductengids/images/'+newShop.companyName+'/'+newShop.imagesPrimaireName.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2')).getDownloadURL().then((url) => { 
                    let newImage ={id : newShop.id, url: url}
                  
                    this.setState(prevState =>({
                        images: [...prevState.images, newImage]
                    }))
                })


                
            })
        })
        
    }


    

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/deelnemers'} />
          }else{
        return (
        <Container fluid>
            
            <Row className="change-row">
        <Col sm={12} className="change-col">
            <div className="change-div"><Button onClick={()=>this.setState({redirect:true})}>Wis filters</Button></div>
        </Col>
    </Row>
            <Row className="participant-row">
                <Col sm={12}>
                    <Container>
                        <Col sm={12} className="participant-title">
                                <h2>Deelnemers</h2>
                                <hr/>
                                <p>Maak kennis met de boeren deelnemers van streekproducten-limburg. Van boeren en bakkers tot kaasmakers en kwekers. Ontmoet de helden die dag en nacht voor jouw eten en drinken klaar staan. En met liefde, passie en op eerlijke wijze produceren. Zij vertellen je hier graag meer over. Want als je weet wat je eet, smaak alles net even lekkerder!</p>
                        </Col>
                    
  

                        <Row>
                            {this.state.items.filter((item)=> item.companyPostal.slice(0, 4) == this.props.data.data).sort((a, b) =>{
    if(a.companyName < b.companyName) { return -1; }
    if(a.companyName > b.companyName) { return 1; }
    return 0;
}).map((item)=>(
 
                            <Col sm={4} className="particioant-col">
                                <div className="participant">
                                    <img src={this.state.images.filter(image => item.id == image.id).map((image)=>(
                                       image.url
                                    ))} />
                                    <div className="participant-content">
                                        <i class="fas fa-map-marker-alt"></i> <span className="participant-location">{item.companyPostal.slice(7, 200)}</span>
                                    
                                    <span className="participant-category">Categorie: {item.category}</span>
                                    <h3>{item.companyName}</h3>
                                    <p dangerouslySetInnerHTML={{ __html:item.desc.slice(0, 180)+'...'}}>
                                    
                                    </p>
                                    <Link to={"/deelnemers/details?id="+item.id}>Meer</Link>
                                    </div>
                                </div>

                            </Col>
                            
                            ))
                            
                            
                            }
                        </Row>
                       

                        
                    
                        
                    </Container>
                </Col>
            </Row>

            
        
            
        </Container>

        );}
    }
};

export default ParticipantIndexPostal;