import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import {Col, Row} from 'shards-react';
import { Container } from "shards-react";

class FooterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         
        };
    }


    toggleHamburger = () => {
        document.querySelector('.navbar-mobile').classList.toggle('showNav');
        document.querySelector('.ham').classList.toggle('showClose');
    }

    render() {

        return (
            <div className="footer-section ">
      
                <Container>
                <Row className="news-letter-footer-row">
                    <Col sm={12} className="news-letter-footer"> 
                       {/*} <h4>Op de hoogte blijven van de laatste ontwikkelingen?</h4>
                        <form><input type="text"/>  <button type="submit" className="subtmit-button" form="hook-form">Inschrijven</button></form>
        */}
                        </Col>
                </Row>
                <Row >
                    

                    <Col sm={11} className="bottom-line">
                        <hr/>
                        <p>All rights reserved @ PixelPro's</p>
                    </Col>
                </Row>

        
                </Container>
            </div>
        );
    }
}

export default FooterComponent;
