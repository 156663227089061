import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import exportFromJSON from 'export-from-json'

class OrdersIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientUUID:'-ohlnfwebk',
            orders:[],
            invoices:[],
            payments:[]
            
        };
    }

    getOrders(){
    
        
    
        let ref = Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/');
        ref.on('value' , snapshot => {
           this.setState({
               orders:[]
           })
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
          
                this.setState(prevState => ({
                    orders: [...prevState.orders, state]
                }))  
            })
        });
        

        

    }



    componentDidMount() {
        this.setState({
            orders:[],
            invoices:[],
            payments:[]
        })

        this.getOrders();
    }
    calculatePrice(items){
        let calcPrice = 0;
     
        for(let i =0; i< items.length;i++){
            let newPrice = items[i].price * items[i].amount;
            
            calcPrice = calcPrice +newPrice;

        }
        
       
        return calcPrice


        console.log(this.state.totalPrice)

    }
    exportBookings(){

        const data = this.renderPayment();

    const fileName = 'download'
    const exportType = 'xls'

    console.log(data)
    console.log(JSON.stringify(data))

    exportFromJSON({ data: data , fileName: 'Inschrijvingen', exportType: exportFromJSON.types.xls })
    }


    renderPayment(){
        let paymentArray = [];
        for(let i =0; i< this.state.orders.length; i++){
        
            let kr= 0;

   
                if(this.state.orders[i].typeShop =='Standaard marktkraam zonder zeil'){
               kr = this.state.orders[i].shopAmount +kr;
            }else{
                kr= 0
            }
            
        
           
            let newItem = {Status: this.state.orders[i].status,Inschrijvingsnummer: this.state.orders[i].orderId, Factuurnummer:this.state.orders[i].orderId, Bedrijf: this.state.orders[i].companyName, Achternaam: this.state.orders[i].lastname,Voornaam: this.state.orders[i].firstname, adres: this.state.orders[i].adres,Plaats: this.state.orders[i].city,Postcode: this.state.orders[i].postal,Land: this.state.orders[i].country,Email: this.state.orders[i].email,Telefoon: this.state.orders[i].phone,KVK: this.state.orders[i].kvk,KR: kr,GR: 0,TeVerhandelenArtikelen: this.state.orders[i].arcticles,FnF: this.state.orders[i].typeFood,Factuurbedrag: this.state.orders[i].typeShop == 'Geen kraam' ? 0 : parseFloat((40-Number(this.state.orders[i].sale != undefined? this.state.orders[i].sale: 0))).toFixed(2),Korting: Number(this.state.orders[i].sale != undefined? this.state.orders[i].sale: 0),FactuurBetaald: this.state.orders[i].invoicePayed,plaatsingsnummer: this.state.orders[i].placementNumber }
            
            paymentArray.push(newItem)

        }

        return paymentArray;
    }

    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
            <Col sm={12} className="products-actions">
                   {/*} <Link>Product bewerken</Link>
                    <Link>Meer acties</Link>*/}
                    <Button className="align-button-right" onClick={()=>this.exportBookings()} >Exporteer inschrijvingen</Button>
        </Col>
           
                {/*<Col sm={12} className="products-actions">
                    <Link>Product bewerken</Link>
                    <Link>Meer acties</Link>
                    <Link className="align-button-right" to={{pathname: `/producten/toevoegen`}} >Product toevoegen</Link>
        </Col>*/}
                <Col sm={12} className="products-table new-table">
                <h3>Nieuwe inschrijvingen</h3>
            <table>
                <tr>
                <th>Inschrijfnummer</th>
                <th>Plaatsingsnummer</th>
                    <th>Achternaam</th>
                    <th>Bedrijfsnaam</th>
                    <th>Beoordeling</th>
                    <th>Betaald</th>
                    <th>Actie</th>
                </tr>

                {/*this.state.orders.sort((a, b) =>  b.orderID > a.orderID  ? 1 : -1)
    .filter((item)=> item.newOrder === true).map((order)=>(
                    <tr >
                        <td>{order.orderID}</td>
                        <td>{order.name}</td>
                       
                        <td>{this.state.payments.filter((item)=> item.orderID === order.orderId).slice(0,1).map((item)=>(new Date(item.date).getDate()+'-'+(Number(new Date(item.date).getMonth())+1)+'-'+new Date(item.date).getFullYear()))}</td>
                        <td>{this.state.payments.filter((item)=> item.orderID === order.orderId).slice(0,1).map((item)=>(item.timeslot))}</td>
                        <td>{this.state.invoices.filter(invoice => order.id == invoice.id).map((invoice)=>(
                                        <a href={invoice.url} target="_blank">Factuur</a>
                                    ))}<Link to={{pathname: `/bestellingen/details`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                    </tr>
                        ))*/}
            

             {this.state.orders.filter((item)=> item.status === "In afwachting").map((order)=>(
                    <tr >
                    <td>{order.orderId}</td>
                    <td>{order.placementNumber != undefined ? order.placementNumber:'-' }</td>
                    <td>{order.lastname}</td>
                   
                    <td>{order.companyName}</td>
                    <td>{order.status == ''?<i class="far fa-clock"></i>:null }{order.status == 'In afwachting'?<i class="far fa-clock"></i>:null }{order.status == 'In behandeling'?<i class="far fa-clock"></i>:null }{order.status == 'Geannuleerd'?<i class="fas fa-times"></i>:null }{order.status === 'Geaccepteerd'?<i class="fas fa-check"></i>:null }</td>
                    <td>{order.invoicePayed == 'In afwachting' ?<i class="far fa-clock"></i>:null }{order.invoicePayed == 'Nee'?<i class="fas fa-times"></i>:null }{order.invoicePayed == 'Ja'?<i class="fas fa-check"></i>:null }</td>
                    <td><Link to={{pathname: `/inschrijvingen/details-beeker`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                </tr>
                        ))}
                
                
            </table>
</Col>
<Col sm={12} className="products-table running-table">
                <h3>Aanvragen in behandeling</h3>
            <table>
                <tr>
                <th>Inschrijfnummer</th>
                <th>Plaatsingsnummer</th>
                    <th>Achternaam</th>
                    <th>Bedrijfsnaam</th>
                    <th>Beoordeling</th>
                    <th>Betaald</th>
                    <th>Actie</th>
                </tr>

                {/*this.state.orders.sort((a, b) =>  b.orderID > a.orderID  ? 1 : -1)
    .filter((item)=> item.newOrder === true).map((order)=>(
                    <tr >
                        <td>{order.orderID}</td>
                        <td>{order.name}</td>
                       
                        <td>{this.state.payments.filter((item)=> item.orderID === order.orderId).slice(0,1).map((item)=>(new Date(item.date).getDate()+'-'+(Number(new Date(item.date).getMonth())+1)+'-'+new Date(item.date).getFullYear()))}</td>
                        <td>{this.state.payments.filter((item)=> item.orderID === order.orderId).slice(0,1).map((item)=>(item.timeslot))}</td>
                        <td>{this.state.invoices.filter(invoice => order.id == invoice.id).map((invoice)=>(
                                        <a href={invoice.url} target="_blank">Factuur</a>
                                    ))}<Link to={{pathname: `/bestellingen/details`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                    </tr>
                        ))*/}
            

            {this.state.orders.filter((item)=>  item.status === "In behandeling").map((order)=>(
                    <tr >
                    <td>{order.orderId}</td>
                    <td>{order.placementNumber != undefined ? order.placementNumber:'-' }</td>
                    <td>{order.lastname}</td>
                   
                    <td>{order.companyName}</td>
                    <td>{order.status == ''?<i class="far fa-clock"></i>:null }{order.status == 'In afwachting'?<i class="far fa-clock"></i>:null }{order.status == 'In behandeling'?<i class="far fa-clock"></i>:null }{order.status == 'Geannuleerd'?<i class="fas fa-times"></i>:null }{order.status === 'Geaccepteerd'?<i class="fas fa-check"></i>:null }</td>
                    <td>{order.invoicePayed == 'In afwachting' ?<i class="far fa-clock"></i>:null }{order.invoicePayed == 'Nee'?<i class="fas fa-times"></i>:null }{order.invoicePayed == 'Ja'?<i class="fas fa-check"></i>:null }</td>
                    <td><Link to={{pathname: `/inschrijvingen/details-beeker`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                </tr>
                        ))}
                
                
            </table>
</Col>
<Col sm={12} className="products-table done-table">
                <h3>Afgehandelde inschrijvingen</h3>
            <table>
                <tr>
                <th>Inschrijfnummer</th>
                <th>Plaatsingsnummer</th>
                    <th>Achternaam</th>
                    <th>Bedrijfsnaam</th>
                    <th>Beoordeling</th>
                    <th>Betaald</th>
                    <th>Actie</th>
                </tr>


                {this.state.orders.filter((item)=>  item.status === "Geaccepteerd").map((order)=>(
                    <tr >
                    <td>{order.orderId}</td>
                    <td>{order.placementNumber != undefined ? order.placementNumber:'-' }</td>
                    <td>{order.lastname}</td>
                   
                    <td>{order.companyName}</td>
                    <td>{order.status == ''?<i class="far fa-clock"></i>:null }{order.status == 'In afwachting'?<i class="far fa-clock"></i>:null }{order.status == 'In behandeling'?<i class="far fa-clock"></i>:null }{order.status == 'Geannuleerd'?<i class="fas fa-times"></i>:null }{order.status === 'Geaccepteerd'?<i class="fas fa-check"></i>:null }</td>
                    <td>{order.invoicePayed == 'In afwachting' ?<i class="far fa-clock"></i>:null }{order.invoicePayed == 'Nee'?<i class="fas fa-times"></i>:null }{order.invoicePayed == 'Ja'?<i class="fas fa-check"></i>:null }</td>
                    <td><Link to={{pathname: `/inschrijvingen/details-beeker`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                </tr>
                        ))}
                
                
            </table>
</Col>
<Col sm={12} className="products-table new-table">
                <h3>Geannuleerde inschrijvingen</h3>
            <table>
                <tr>
                <th>Inschrijfnummer</th>
                <th>Plaatsingsnummer</th>
                    <th>Achternaam</th>
                    <th>Bedrijfsnaam</th>
                    <th>Beoordeling</th>
                    <th>Betaald</th>
                    <th>Actie</th>
                </tr>

                {/*this.state.orders.sort((a, b) =>  b.orderID > a.orderID  ? 1 : -1)
    .filter((item)=> item.newOrder === true).map((order)=>(
                    <tr >
                        <td>{order.orderID}</td>
                        <td>{order.name}</td>
                       
                        <td>{this.state.payments.filter((item)=> item.orderID === order.orderId).slice(0,1).map((item)=>(new Date(item.date).getDate()+'-'+(Number(new Date(item.date).getMonth())+1)+'-'+new Date(item.date).getFullYear()))}</td>
                        <td>{this.state.payments.filter((item)=> item.orderID === order.orderId).slice(0,1).map((item)=>(item.timeslot))}</td>
                        <td>{this.state.invoices.filter(invoice => order.id == invoice.id).map((invoice)=>(
                                        <a href={invoice.url} target="_blank">Factuur</a>
                                    ))}<Link to={{pathname: `/bestellingen/details`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                    </tr>
                        ))*/}
            

             {this.state.orders.filter((item)=> item.status === "Geannuleerd").map((order)=>(
                    <tr >
                    <td>{order.orderId}</td>
                    <td>{order.placementNumber != undefined ? order.placementNumber:'-' }</td>
                    <td>{order.lastname}</td>
                   
                    <td>{order.companyName}</td>
                    <td>{order.status == ''?<i class="far fa-clock"></i>:null }{order.status == 'In afwachting'?<i class="far fa-clock"></i>:null }{order.status == 'In behandeling'?<i class="far fa-clock"></i>:null }{order.status == 'Geannuleerd'?<i class="fas fa-times"></i>:null }{order.status === 'Geaccepteerd'?<i class="fas fa-check"></i>:null }</td>
                    <td><i class="fas fa-times"></i></td>
                    <td><Link to={{pathname: `/inschrijvingen/details-beeker`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                </tr>
                        ))}
                
                
            </table>
</Col>
   
            </Row>
            </div>



        );
    }
};

export default OrdersIndex;