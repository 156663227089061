import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';

import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Firebase from 'firebase';




class OrdersDetailsBeekerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status:'Afgehandeld',
            payment:[],
            items:[],
            setDate:'',
            totalPrice:0,
            invoiceUrl:'',
            sale:0
      };
    }

    componentDidMount(){
     
        let items = Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/'+this.props.billData.billData.id+'/items/');
        items.on('value' , snapshot => {
                            
                snapshot.forEach((childSnap) => {
                    let item = childSnap.val();
                    console.log(item)
                    this.calculatePrice(item)
                    this.setState(prevState => ({
                        items: [...prevState.items, item],
                    }));
                

                })
           
        });
        console.log(this.props.billData.billData);
      
        this.setState({
            status:this.props.billData.billData.status,
            invoicePayed:this.props.billData.billData.invoicePayed 
        })
if(this.props.billData.billData.invoiceUrl != ''){


        let invoices = Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/'+this.props.billData.billData.id);
        invoices.on('value' , snapshot => {
            this.setState({invoices:[]})
            let state = snapshot.val();
            console.log(state.invoiceUrl)
            Firebase.storage().ref(state.invoiceUrl).getDownloadURL().then((url) => {
                console.log(url)
                console.log(state)
                this.setState({
                    invoiceUrl: url
                })
                

              
              })
       

        })
    }
       

    }

    totalPrice= 0;

    calculatePrice(item){
       
        let newPrice = item.price * item.amount;
        this.totalPrice =this.totalPrice + newPrice
        let setPrice = this.state.totalPrice + newPrice;

        console.log(setPrice)

        this.setState({
            totalPrice: setPrice
        })

       

    }

    sendNoneInvoiceMail(){
        console.log('hereooo')
        let headers = new Headers();

console.log(this.state.items.length)
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin','localhost:3000');
   // headers.append('Origin','https://inno-fruit.beterbijons.nl/')
    headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")

   
    fetch("https://us-central1-cashierr-pixelpros.cloudfunctions.net/sendConfirmBeekerMail", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({


            email:  this.props.billData.billData.email,
           
   
        })
      })
      .then(res => {
        toast.success('Mail verzonden');
      }).catch((error)=>{
        //error callback
        console.log('error ' , error)
    })
    }


   
    changeStatus(event){
        
        console.log('type is')
        if(event === 'Geaccepteerd'){
         
            Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/'+this.props.billData.billData.id).update({
               newOrder:false,
               status:event,
    
        
            }).then((data)=>{
    this.setState({
        status:event
    })
   

           if(this.props.billData.billData.typeShop != 'Standaard marktkraam zonder zeil'){
               console.log('send mail')
            this.sendNoneInvoiceMail()
           } 
               
    
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
           

        }else if(event === 'In behandeling'){
            Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/'+this.props.billData.billData.id).update({
                newOrder:false,
                status:event,
     
         
             }).then((data)=>{
                this.setState({
                    status:event
                })
                toast.success('Status van de inschrijving is in behandeling');
     
                
     
             }).catch((error)=>{
                 //error callback
                 console.log('error ' , error)
             })
        }
        else if(event === 'Geannuleerd'){
            Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/'+this.props.billData.billData.id).update({
                newOrder:false,
                status:event,
     
     
         
             }).then((data)=>{
                this.setState({
                    status:event
                })
                let headers = new Headers();

console.log(this.state.items.length)
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin','localhost:3000');
   // headers.append('Origin','https://inno-fruit.beterbijons.nl/')
    headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")

   
    fetch("https://us-central1-cashierr-pixelpros.cloudfunctions.net/cancelMarktcommissie", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({


            email:  this.props.billData.billData.email,
           
            orderId:this.props.billData.billData.orderId,
   
        })
      })
      .then(res => {
        toast.success('Status veranderd naar afgeronde inschrijvingen als geannulleerd');
      }).catch((error)=>{
        //error callback
        console.log('error ' , error)
    })
               
     
                
     
             }).catch((error)=>{
                 //error callback
                 console.log('error ' , error)
             })
        }

        //toast.error('er gaat iets mis probeer het opnieuw')
    }

    sendInvoice(){
        

       Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/'+this.props.billData.billData.id).update({
            sendInvoice:true,
            saleActive: this.state.sale != ''? true:false,
            sale:this.state.sale
         })
 let headers = new Headers();

console.log(this.state.items.length)
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin','localhost:3000');
   // headers.append('Origin','https://inno-fruit.beterbijons.nl/')
    headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")

   
    fetch("https://us-central1-cashierr-pixelpros.cloudfunctions.net/invoiceMarktcommissieBeeker", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            id:this.props.billData.billData.id,
            name: this.props.billData.billData.companyName,
            firstname:this.props.billData.billData.firstname,
            lastname:this.props.billData.billData.lastname,
            adres:this.props.billData.billData.adres,
            postal:this.props.billData.billData.postal,
            city:this.props.billData.billData.city,
            country:this.props.billData.billData.country,
            email:  this.props.billData.billData.email,
            phone:  this.props.billData.billData.phone,
            items: this.props.billData.billData.typeShop =='Standaard marktkraam zonder zeil' ? true:false,

 
            orderId:this.props.billData.billData.orderId,
            saleActive: this.state.sale != ''? true:false,
            sale:this.state.sale
   
        })
      })
      .then(res => {
        
      }).catch((error)=>{
        //error callback
        console.log('error ' , error)
    })
    toast.success('Factuur is verzonden');
    }

    changePaymentStatus(event){

        if(event === 'Ja'){
            Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/'+this.props.billData.billData.id).update({
                invoicePayed: 'Ja',
             })
             toast.success('Wijziging verwerkt');
        }else{
            Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/'+this.props.billData.billData.id).update({
                invoicePayed: 'Nee',
             })
            toast.success('Wijziging verwerkt');
        }
       
    }

    render() {  

        
        return (
            <div>
         <Toaster/>
                <Row >
                <Col sm={12}>
 <Link className="back-link" to={{pathname: `/inschrijvingen-beekerondernemer`}} >Terug</Link>
 </Col>
                    <Col sm={6} className="bill-detail-list">
                        <ul>
                        <li>Order id:<span className="bill-detail-list-element">{this.props.billData.billData.orderId}</span></li>
                            <li>Bedrijfsnaam:<span className="bill-detail-list-element">{this.props.billData.billData.companyName}</span></li>
                            <li>Voornaam + achternaam:<span className="bill-detail-list-element">{this.props.billData.billData.firstname} {this.props.billData.billData.lastname}</span></li>
                            <li>E-mail:<span className="bill-detail-list-element">{this.props.billData.billData.email}</span></li>
                            <li>Telefoon:<span className="bill-detail-list-element">{this.props.billData.billData.phone}</span></li>
                            <li>Adres:<span className="bill-detail-list-element"> {this.props.billData.billData.adres}, {this.props.billData.billData.city}</span></li>
                            <li>Geopend/Gesloten:<span className="bill-detail-list-element"> {this.props.billData.billData.openClose}</span></li>
                            
                            <li>Factuur verzonden:<span className="bill-detail-list-element">{this.props.billData.billData.sendInvoice == undefined && this.props.billData.billData.invoiceUrl == undefined ? 'Nee' :null} {this.props.billData.billData.sendInvoice && this.props.billData.billData.invoiceUrl == undefined ? <i class="far fa-clock"></i> :null}{this.props.billData.billData.sendInvoice && this.props.billData.billData.invoiceUrl != '' ? <i class="far fa-check-circle"></i> :null}</span></li>
                            <li>Factuur:<span className="bill-detail-list-element"> {this.state.invoiceUrl != ''?<a href={this.state.invoiceUrl} target="_blank">Bekijk factuur</a> :null}</span></li>
                            <li>Factuurbedrag: <span className="bill-detail-list-element"> {this.props.billData.billData.typeShop == 'Standaard marktkraam zonder zeil' ? "€ "+(Number(40)-(this.props.billData.billData.sale != undefined ? this.props.billData.billData.sale != 0 ? Number(this.props.billData.billData.sale):0:0)).toFixed(2) : "n.v.t."}</span></li>
                            <li>Korting: <span className="bill-detail-list-element">{this.props.billData.billData.sale != 0 ? "€ "+ Number(this.props.billData.billData.sale).toFixed(2):'n.v.t.'}</span></li>
                        </ul>
                    </Col>
                    <Col sm={5} className="bill-detail-list">
                    <span className="input-full-width input-status">
                        <label>Status</label><br/>
                        <select name="orderCountry" value={this.state.status} onChange={(event)=> this.changeStatus(event.target.value)}>
                            <option value="selected" disabled>Selecteer een status</option>
                            <option value="In afwachting">In afwachting</option>
                            <option value="In behandeling">In behandeling</option>
                            <option value="Geaccepteerd">Geaccepteerd</option>
                            <option value="Geannuleerd">Geannuleerd</option>
                        </select>
                    </span>
                    {this.props.billData.billData.typeShop == 'Standaard marktkraam zonder zeil' ?
                    <span>
                    <span className="input-full-width input-status">
                        <label>Euro's korting:</label><br/>
                            <input type="number" value={this.state.sale} onChange={(event)=>this.setState({sale:event.target.value})} />
                        </span>
                   
                        <Button className="align-button-right details-button" onClick={()=>this.sendInvoice()} >Factuur verzenden</Button>
                        {this.props.billData.billData.sendInvoice != ''?this.props.billData.billData.sendInvoice == true?
                          <span className="input-full-width input-status">
                        <label>Factuur betaald</label><br/>
                        <select name="orderCountry" value={this.state.invoicePayed} onChange={(event)=> this.changePaymentStatus(event.target.value)}>
                            <option value="In afwachting">In afwachting</option>
                            <option value="Ja">Ja</option>
                            <option value="nvt">nvt</option>
                            
                        </select>
                    </span>:null:null}</span>:null}
                    </Col>
                    <Col sm={8}></Col>
                    <Col sm={11} className="bills-component">

<table className="bills-table"> 
  <tr>
    
    <th>Naam</th>
    <th>Aantal</th>
   

  </tr>

    <tr className="items-table">
        <td>{this.props.billData.billData.typeShop}</td>
        <td>{this.props.billData.billData.shopAmount}</td>
      
  
  </tr>

  


    
                   
 </table>
 </Col>
                
 <Col sm={6} className="bill-detail-list margin-table">
                        <ul>
                        <li>Interesse in sponsoring:<span className="bill-detail-list-element">{this.props.billData.billData.sponsoringCheck ? 'Ja' :'Nee'}</span></li>
                            <li>Heeft de brief ontvangen:<span className="bill-detail-list-element">{this.props.billData.billData.alreadyBeenThere}</span></li>
                            <li>Opmerkingen<span className="bill-detail-list-element">{this.props.billData.billData.desc == '' ? 'n.v.t.' :this.props.billData.billData.desc}</span></li>
                           
                        </ul>
                    </Col>

 
 <Col sm={12} className="black-space">

 </Col>
                </Row>
                
            
            
            </div>
        );
    }
};

export default OrdersDetailsBeekerComponent;
