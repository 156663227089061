import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, Container } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapContainer from './MapContainer';
import { Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import toast, { Toaster } from 'react-hot-toast';
import uuid from 'react-uuid'
import moment from 'moment';
import ReactStars from "react-rating-stars-component";
const mapStyle = {
    width: '100%',
    height: 800,
    position: 'absolute'
}





class BaekvoitureComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            loader: false,
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            adres: '',
            postal: '',
            city: '',
            country: '',
            type: '',
            typeCar: '',
            brandCar: '',
            numberCar: '',
            buildCar: '',
            amountPerson: '',
            desc: '',
            imageUrl: '',
            genrale: false,
            policy: false,
            informationCorrect: false,
            totalPayments: [],
            orderId: '',
            errorMail: false,
            emailSecond: '',

        };
    }

    controlSecondEmail(mail) {
        this.setState({
            emailSecond: mail,
            errorMessage: ''
        })

        setTimeout(() => {
            if (this.state.emailSecond != this.state.email) {
                this.setState({
                    errorMail: true
                })

            } else {
                this.setState({
                    errorMail: false
                })
            }
        }, 2000)
    }

    componentDidMount() {
        // window.location.replace("https://marktcom-beek.nl/");
        let payments = Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requestsBeeker/');
        payments.on('value', snapshot => {
            snapshot.forEach((childSnap) => {
                let payment = childSnap.val();

                //let newImage = {id: this.products[i].winkelier,url:newImages.url, productId:this.products[i].id}

                this.setState(prevState => ({
                    totalPayments: [...prevState.totalPayments, payment],
                }));

            });
        })

        let paymentsTwo = Firebase.database().ref('/clients/-907c8a70q98nlcnl9/requests/');
        paymentsTwo.on('value', snapshot => {
            snapshot.forEach((childSnap) => {
                let payment = childSnap.val();

                //let newImage = {id: this.products[i].winkelier,url:newImages.url, productId:this.products[i].id}

                this.setState(prevState => ({
                    totalPayments: [...prevState.totalPayments, payment],
                }));

            });
        })

        let paymentsThree = Firebase.database().ref('/clients/-907c8a70q98nlcnl9/baek-voiture/');
        paymentsThree.on('value', snapshot => {
            snapshot.forEach((childSnap) => {
                let payment = childSnap.val();

                //let newImage = {id: this.products[i].winkelier,url:newImages.url, productId:this.products[i].id}

                this.setState(prevState => ({
                    totalPayments: [...prevState.totalPayments, payment],
                }));

            });
        })

        setTimeout(() => {

            let date = new Date();
            let lastNumber = this.state.totalPayments.length > 99 ? this.state.totalPayments.length : this.state.totalPayments.length > 9 ? '0' + this.state.totalPayments.length : '00' + this.state.totalPayments.length;
            let DayOfMonth = Number(date.getDate()) > 9 ? date.getDate() : '0' + date.getDate();
            let orderName = "BV" + date.getFullYear() + '0' + (Number(date.getMonth()) + 1) + DayOfMonth + lastNumber;

            this.setState({
                orderId: orderName
            })
            console.log("orderId is: " + this.state.orderId)

        }, 2000);

    }

    generateUniqueOrderIDMarkcom() {
        return Firebase.database().ref('/clients/-907c8a70q98nlcnl9/OrderNumber').transaction((currentOrderNumber) => {
            return (currentOrderNumber || 0) + 1;
        }).then((result) => {
            if (result.committed) {
                // Formatteer de huidige datum als "YYYYMMDD"
                const formattedDate = moment().format('YYYYMMDD');
                // Zorg dat het unieke nummer minstens twee cijfers heeft
                const formattedOrderNumber = String(result.snapshot.val()).padStart(3, '0');
                // Voeg "BM" toe en combineer met de geformatteerde datum en het unieke nummer
                return `BV${formattedDate}${formattedOrderNumber}`;
            } else {
                throw new Error('Transactie niet geslaagd.');
            }
        });
    }

    async sendRequest() {
        this.setState({
            loader: true,
        })
        let Order_ID = await this.generateUniqueOrderIDMarkcom();
        setTimeout(() => {
            localStorage.setItem('email', this.state.email)




            var key = Firebase.database().ref().child('/clients/-907c8a70q98nlcnl9/baek-voiture/').push().key;
            Firebase.database().ref('/clients/-907c8a70q98nlcnl9/baek-voiture/' + key).set({
                id: key,
                form: 'Baek-voiture',
                orderId: Order_ID,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                phone: this.state.phone,
                adres: this.state.adres,
                postal: this.state.postal,
                city: this.state.city,
                country: this.state.country,
                type: this.state.type,
                typeCar: this.state.typeCar,
                brandCar: this.state.brandCar,
                numberCar: this.state.numberCar,
                buildCar: this.state.buildCar,
                amountPerson: this.state.amountPerson,
                desc: this.state.desc,
                imageUrl: this.state.imageUrl,
                invoicePayed: 'In afwachting',
                status: 'In afwachting',
                price: {
                    check: 'in progress'
                },
                rating: {
                    check: 'in progress'
                }
            }).then(() => {
                let headers = new Headers();

                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                headers.append('Origin', 'http://localhost:3000');
                //headers.append('Origin','https://frontend-test-bbo.web.app/')
                headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
                fetch("https://us-central1-cashierr-pixelpros.cloudfunctions.net/confirmBaekvoiture", {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({ email: this.state.email, orderId: Order_ID })
                })

            }).then(() => {
                let headers = new Headers();

                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');
                headers.append('Origin', 'http://localhost:3000');
                //headers.append('Origin','https://frontend-test-bbo.web.app/')
                headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
                fetch("https://us-central1-cashierr-pixelpros.cloudfunctions.net/confirmMarktcommissieIntern", {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({})
                })


            }).then(() => {
                setTimeout(() => {
                    this.setState({
                        loader: false,
                        redirect: true
                    })

                }, 2000)
            })
        }, 2000);




    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    handleUploadCompanyLogo(e) {

        let logoFile = e.target.files[0];
        const uniqueId = this.makeid(12);
        const { imageCompanyLogo } = this.state;
        this.setState({
            uploaded: true,
            loadingComapny: true,
            handleUpload: true
        })
        const query = new URLSearchParams(this.props.id);


        if (logoFile != null) {
            const uploadTask = Firebase.storage().ref(`BaekVoiture/attachments/${uniqueId}${logoFile.name}`).put(logoFile);
            uploadTask.on(
                'state_changed',
                snapshot => {
                },
                error => {
                    console.log(error);
                },
                () => {
                    Firebase.storage()
                        .ref('BaekVoiture/attachments/')
                        .child(uniqueId + logoFile.name)
                        .getDownloadURL()
                        .then(url => {
                            toast.success('Afbeelding opgeslagen');

                            this.setState(prevState => ({
                                imageUrl: url,

                            }))
                        }).then(() => {
                            this.setState({ overlay: false })
                            //  this.updateData();
                            // setTimeout(() => {  this.getUrl(this.state.companyName,this.state.imagesCompanyName.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2')) }, 6000);

                        }).then(() => {
                            console.log(this.state.companyName)
                            this.setState({
                                loadingComapny: true
                            })


                        });
                }
            );
        } else {
            this.setState({
                errorMessageLogo: 'Er is geen foto geselecteert.',
                uploaded: false,
                uploading: false
            })

        }


    }



    render() {
        if (this.state.redirect) {
            return <Redirect to={'/bedankt-baek'} />
        }


        return (

            <Container className="home-page">

                <div className="row">
                    <div className="col-sm-6">
                        <h2>Inschrijving Baek Voiture</h2>

                        <span className="input-field-request">
                            <label>Voornaam/ Voorletters:  </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.firstname} onChange={event => this.setState({ firstname: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Achternaam: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.lastname} onChange={event => this.setState({ lastname: event.target.value, errorMessage: '' })} />
                        </span>

                        <span className="input-field-request">
                            <label>E-mailadres: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.email} onChange={event => this.setState({ email: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Telefoon: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.phone} onChange={event => this.setState({ phone: event.target.value, errorMessage: '' })} />
                        </span>
                    </div>
                    <div className="col-sm-6 right-colum-request">

                        <span className="input-field-request ">
                            <label>Adres: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.adres} onChange={event => this.setState({ adres: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Postcode: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.postal} onChange={event => this.setState({ postal: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Plaats: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.city} onChange={event => this.setState({ city: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Land: </label>
                            <br />
                            <select value={this.state.country} onChange={(event) => this.setState({ country: event.target.value })}>
                                <option>Selecteer een land</option>
                                <option value="Nederland" >Nederland</option>
                                <option value="België">België</option>
                                <option value="Duitsland">Duitsland</option>
                            </select>

                        </span>
                    </div>

                    <div className="col-sm-6 request-col">

                        <h2>Deelname</h2>
                        <span className="input-field-request">
                            <label>Inschrijven voor: </label>
                            <br />
                            <select value={this.state.type} onChange={(event) => this.setState({ type: event.target.value })}>
                                <option>Maak een keuze</option>
                                <option value="Toertocht - korte route" >Toertocht - korte route</option>
                                <option value="Toertocht - lange route">Toertocht - lange route</option>
                                <option value="Presentatie">Presentatie</option>
                            </select>
                        </span>
                        <span className="input-field-request">
                            <label>Soort voertuig: </label>
                            <br />
                            <select value={this.state.typeCar} onChange={(event) => this.setState({ typeCar: event.target.value })}>
                                <option>Maak een keuze</option>
                                <option value="Auto" >Auto</option>
                                <option value="Vrachtauto">Vrachtauto</option>
                                <option value="Motor">Motor</option>
                                <option value="Bromfiets">Bromfiets</option>
                            </select>
                        </span>
                        <span className="input-field-request">
                            <label>Merk en type: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.brandCar} onChange={event => this.setState({ brandCar: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Kenteken: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.numberCar} onChange={event => this.setState({ numberCar: event.target.value, errorMessage: '' })} />
                        </span>
                        <span className="input-field-request">
                            <label>Bouwjaar: </label>
                            <br />
                            <input required type="text" name="company-name" value={this.state.buildCar} onChange={event => this.setState({ buildCar: event.target.value, errorMessage: '' })} />
                        </span>
                    </div>
                    <div className="col-sm-6 right-colum-request voiture">

                        <span className="input-field-request">
                            <label>Aantal deelnemers (inclusief bestuurder): </label>
                            <br />
                            <select value={this.state.amountPerson} onChange={(event) => this.setState({ amountPerson: event.target.value })}>
                                <option>Maak een keuze</option>
                                <option value="1" >1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                            <p>
                                <br />
                                Kosten per voertuig met bestuurder : 	<b>€ 17,50</b><br />
                                Kosten voor elke extra deelnemer : 	<b>€   7,50</b>

                            </p>
                        </span>
                        <span className="input-field-request">
                            <label>Informatie over dit voertuig voor presentator: </label>
                            <br />
                            <textarea value={this.state.desc} onChange={event => this.setState({ desc: event.target.value, errorMessage: '' })} />

                        </span>
                        <span className="input-field-request">
                            <label>Foto van het deelnemende voertuig:</label>
                            <br />
                            <input type="file" onChange={(event) => this.handleUploadCompanyLogo(event)} />
                            <br />
                            <p><i>Wij behouden ons de vrijheid om de toegevoegde foto te gebruiken als promotie voor onze website.</i></p>
                        </span>
                        <span className="input-field-request">
                            <label>E-mailadres ter controle: </label>
                            <br />

                            <input required type="text" name="company-name" value={this.state.emailSecond} onChange={event => this.controlSecondEmail(event.target.value)} />


                        </span>







                    </div>
                    <div className="col-sm-6">

                    </div>
                    <div className="col-sm-6 button-col">
                        <label style={{ width: '100%', marginTop: '-2%' }}><input type="checkbox" checked={this.state.informationCorrect} onClick={() => this.setState({ informationCorrect: this.state.informationCorrect ? false : true })} /> Ik heb alle gegevens correct ingevuld.</label>
                        <label style={{ width: '100%', marginTop: '-2%' }}><input type="checkbox" checked={this.state.genrale} onClick={() => this.setState({ genrale: this.state.genrale ? false : true })} /> Ik heb kennis genomen van de <a href="https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/marktcom%2FSMB%20Algemene%20voorwaarden%20deelname%20Jaarmarkt%20Beek%20en%20Baek%20Voiture%202024.pdf?alt=media&token=f1f1f9dc-5cec-4983-a36d-e5a3fad1f06b" target="_blank">SMB algemene voorwaarden</a>.</label>
                        <label style={{ width: '100%', marginTop: '-2%' }}><input type="checkbox" checked={this.state.policy} onClick={() => this.setState({ policy: this.state.policy ? false : true })} /> Ik heb kennis genomen van de <a href="https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/marktcom%2FSMB%20Privacy%20Policy%20-%202024.pdf?alt=media&token=ebc98ffa-7805-4b5e-ad27-0fe9b8727848" target="_blank">SMB privacy policy.</a></label><br /><br />
                        {this.state.errorMail
                            ? <div className='warning'>
                                <p>E-mail adres komt niet overeen met eerder opgegeven adres.</p>
                            </div> : null}
                        {this.state.loader ? <button className="default-button-marktcommisie"><Loader type="Bars" color="#fff" height={20} width={20} /></button>
                            : this.state.genrale == true &&
                                this.state.errorMail == false &&
                                this.state.informationCorrect == true &&

                                this.state.policy == true &&
                                this.state.email != '' &&
                                this.state.phone != '' &&
                                this.state.adres != '' &&
                                this.state.typeCar != '' &&
                                this.state.brandCar != '' &&
                                this.state.numberCar != '' &&
                                this.state.buildCar != '' &&
                                this.state.amountPerson != ''
                                ?

                                <button className="default-button-marktcommisie" onClick={() => this.sendRequest()}>Verzend aanvraag</button> : <button disabled className="default-button-marktcommisie disabled-button" onClick={() => this.sendRequest()}>Verzend aanvraag</button>}
                    </div>
                    <div className="col-sm-12 request-overview-col">
                        <h2>Overzicht</h2>
                        <div className="row">
                            <div className="col-sm-6 request-overview-detail">
                                <h3>Algemene gegevens</h3>
                                <ul>
                                    <li><span className="left-li">Voornaam + Achternaam:</span><span className="right-li">{this.state.firstname} {this.state.lastname}</span></li>
                                    <li><span className="left-li">E-mail:</span><span className="right-li">{this.state.email}</span></li>
                                    <li><span className="left-li">Telefoon:</span><span className="right-li">{this.state.phone}</span></li>
                                    <li><span className="left-li">Adres:</span><span className="right-li">{this.state.adres} , {this.state.postal}, {this.state.city}, {this.state.country}</span></li>
                                </ul>
                            </div>

                            <div className="col-sm-6 request-overview-detail">
                                <h3>Deelname</h3>
                                <ul>
                                    <li><span className="left-li">Inschrijven voor:</span><span className="right-li">{this.state.type}</span></li>
                                    <li><span className="left-li">Soort voertuig:</span><span className="right-li">{this.state.typeCar} </span></li>
                                    <li><span className="left-li">Merk auto:</span><span className="right-li">{this.state.brandCar}</span></li>
                                    <li><span className="left-li">Kenteken:</span><span className="right-li">{this.state.numberCar}</span></li>
                                    <li><span className="left-li">Bouwjaar:</span><span className="right-li">{this.state.buildCar}</span></li>
                                    <li><span className="left-li">Aantal personen in voertuig: </span><span className="right-li">{this.state.amountPerson}</span></li>

                                </ul>
                            </div>

                        </div>


                    </div>


                </div>
            </Container>

        );
    }
};

export default BaekvoitureComponent;