import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';

import { Container } from "shards-react";

import { Link } from 'react-router-dom';

class ShopIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productsAdded: false,
            bagItems:[],
            productImages:[],
            allTotals:0,
            companyImages:[],
            recalcPrice:false
 

        };
    }

    products = [];

    checkProducts(){
      
        this.products = JSON.parse(localStorage.getItem('shoppingBag')) ;
     
             for(let i = 0; i< this.products.length; i++){

console.log()
                let products = Firebase.database().ref('/clients/-ljcbwenpidwdkenw/products/'+this.products[i].id);
              products.on('value' , snapshot => {
                    let state = snapshot.val();

                    
    

                    let addedProduct = {name:state.name,price:state.price,amount:this.products[i].amount, id:this.products[i].id,sidedish:this.products[i].sideDish,uniqueId:this.products[i].uniqueId}
                  //  let totalAmount = parseFloat() *this.products[i].amount;

              
                   
                    this.setState(prevState =>({
                        bagItems: [...prevState.bagItems,addedProduct],
                    }))
                   this.calculateTotel(state.price)
          
                    
                   
                   
                })
                
                
            }


          
    }


    totalPrice = 0;

    calculateTotel(newPayment){
  

        let payment=  this.state.allTotals+ newPayment;

        console.log(payment)

        let totalNew =this.totalPrice + newPayment;

       this.setState({
            allTotals:totalNew
        })

        this.totalPrice = totalNew;

        localStorage.setItem('total',(this.totalPrice*100).toFixed(0))

        

    }

 

    componentDidMount= async() =>{
        window.scrollTo(0, 0);
       if(localStorage.getItem('shoppingBag') != null){
           this.products =[];
            await this.checkProducts();
            this.setState({
                productsAdded:true
            })


            setTimeout(() => {
this.setState({allTotals:this.state.allTotals})
            },1000);
          
            
        }
    }

    deleteFromShoppingbag(product){
        console.log(parseFloat(this.state.allTotals).toFixed(2))



            this.setState({
                bagItems: this.state.bagItems.filter((item)=> item.uniqueId !== product.uniqueId),
                allTotals:parseFloat( this.state.allTotals).toFixed(2) - parseFloat(product.price).toFixed(2),
              
            })
 

            this.setState({
                recalcPrice:true
              })
  


       

   

        localStorage.setItem('shoppingBag', JSON.stringify(JSON.parse(localStorage.getItem('shoppingBag')).filter((item)=> item.uniqueId !== product.uniqueId)))
      console.log(product)
      setTimeout(() => {
      localStorage.setItem('total',(this.state.allTotals*100).toFixed(0))
     
    }, 1000);
  

       
    }




    

    

    render() {
        return (
           

                    

                <Col sm={12}>
                    <Container>
                        <Row>
                            <Col sm={12} className="breadcrumbs-col">
                               <span className="breadcrumbs"> <Link to={'../home'}>Home</Link> / <Link to={'#'}>Winkelwagen</Link>  </span>
                              
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid>
                        <hr  className="dotted-full-line"></hr>
                    </Container>
                    <Container>
                    
                        <Row >
                            <Col sm={12} className="checkout-detail shopping-bag">
                            <h2>Bestelling</h2>
                            {this.state.productsAdded?
                            <span>
                               <ul className="checkout-shoppinglist">
                                   {this.state.bagItems.map((item)=>(
                                        <li>
                                           <span className="product-title-shoppinglist">{item.name}<br/> {item.sidedish}</span><span className="product-amount-shoppinglist">{item.amount} x</span><span className="product-price-shoppinglist">€ {item.price.toFixed(2)}</span> <span className="delete-price-shoppinglist"><i onClick={()=>this.deleteFromShoppingbag(item)} class="fas fa-trash"></i></span></li>
                                   ))}
                                  
                                   
                               </ul>
                               <span className="total-shopping-bag">
                               <hr/>
                               <p>Subtotaal <span className="checkout-shoppinglist-total">€ {this.state.allTotals.toFixed(2)}</span></p>
                              {/*} <p>Aantal afhaallocaties <span className="checkout-shoppinglist-total">2</span></p>*/}
                               <p><b>Totaal</b><span className="checkout-shoppinglist-total"><b>€ {this.state.allTotals.toFixed(2)}</b></span></p>
                               <Link className="pay-bill-button" to={"/afrekenen"}>Naar bestellen</Link></span>
                               </span>
                                :
                                <span>Je hebt momenteel geen producten in je winkelmand</span>}
                            </Col>
                            
                        </Row>

                        
                    </Container>
                </Col>
               
               
                
                    
   

            
       



        );
    }
};

export default ShopIndex;