import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import HeaderBackendComponent from "../../components/Header/headerBackendMobile";
import OrdersIndex from "../../components/orders";
import OrdersDetailsComponent from "../../components/orders/orderDetails";



const OrdersDetailView = (props) => (
  <Container  className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Inschrijvingen" subtitle="Jaarmarkt Beek" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <HeaderBackendComponent />
  <OrdersDetailsComponent billData={props.location.state}/>
    
  </Container>
);

export default OrdersDetailView;
