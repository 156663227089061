import React from "react";
import { Container, Row, Col } from "shards-react";

import FooterComponent from "../../components/Footer/FooterComponent";
import CheckPaymentComponent from "../../components/frontend/CheckPaymentComponent";
import ThankYouBaekComponent from "../../components/frontend/ThankYouBaek";
import ThankYouComponent from "../../components/frontend/ThankYouComponent";
import HeaderComponent from "../../components/Header/headerComponent";



const ThankYouBaekView = (props) => (
    <div>
    <HeaderComponent/>
    <ThankYouBaekComponent  />
    <FooterComponent/>
    
    </div>
);

export default ThankYouBaekView;
