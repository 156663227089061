import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, ModalLayout } from "./layouts";
import AdminPanel from "./views/Admin/AdminPanelView";
import SignOnCompleteView from "./views/Authentication/SignOnCompleteView";
import SignOnView from "./views/Authentication/SignOnView";
import Changes from "./views/Changes/ChangesView";
import ChangesReview from "./views/Changes/ReviewChanges";
import Downloads from "./views/Downloads/IndexView";
import FrontendHome from "./views/Frontend/FrontendHome";
import LoginScreen from "./views/LoginScreen";
import Message from "./views/Messages/MessageView";
import BaekVoitureOrderView from "./views/Orders/BaekVoitureOrderView";
import BeekerOndernemerOrderView from "./views/Orders/BeekerOndernemerOrderView";
import OrdersDetailBaekView from "./views/Orders/OrdersDetailsBaekView";
import OrdersDetailBeekerView from "./views/Orders/OrdersDetailsBeekerView";
import OrdersDetailView from "./views/Orders/OrdersDetailsView";
import Orders from "./views/Orders/OrdersView";
import AddProductsView from "./views/Products/AddProductsView";
import ChangeProductsView from "./views/Products/ChangeProductsView";
import Products from "./views/Products/ProductsView";
import Profile from "./views/Profile/ProfileView";


// Route Views

import SignInScreen from "./views/signInView";



export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/inschrijvingen" />
  },
 
  {
    path: "/aanmeldingen",
    exact: true,
    layout: DefaultLayout,
    component: Changes
  },
  {
    path: "/aanmeldingen/beoordelen",
    exact: true,
    layout: DefaultLayout,
    component: ChangesReview
  },
  {
    path: "/producten",
    exact: true,
    layout: DefaultLayout,
    component: Products
  },
  {
    path: "/producten/toevoegen",
    exact: true,
    layout: DefaultLayout,
    component: AddProductsView
  },
  {
    path: "/producten/aanpassen",
    exact: true,
    layout: DefaultLayout,
    component: ChangeProductsView
  },
  {
    path: "/inschrijvingen",
    exact: true,
    layout: DefaultLayout,
    component: Orders
  },
  {
    path: "/inschrijvingen-beekerondernemer",
    exact: true,
    layout: DefaultLayout,
    component: BeekerOndernemerOrderView
  },
  {
    path: "/inschrijvingen-baekvoiture",
    exact: true,
    layout: DefaultLayout,
    component: BaekVoitureOrderView
  },
 {
    path: "/inschrijvingen/details",
    exact: true,
    layout: DefaultLayout,
    component: OrdersDetailView
  },
  {
    path: "/inschrijvingen/details-beeker",
    exact: true,
    layout: DefaultLayout,
    component: OrdersDetailBeekerView
  }, {
    path: "/inschrijvingen/details-baek",
    exact: true,
    layout: DefaultLayout,
    component: OrdersDetailBaekView
  },
  {
    path: "/profiel",
    exact: true,
    layout: DefaultLayout,
    component: Profile
  },
  {
    path: "/berichten",
    exact: true,
    layout: DefaultLayout,
    component: Message
  },
  {
    path: "/downloads",
    exact: true,
    layout: DefaultLayout,
    component: Downloads
  },
  {
    
    path: "/sign-in",
    layout: ModalLayout,
    exact: true,
    component: SignInScreen
  },
  {
    
    path: "/admin",
    layout: DefaultLayout,
    exact: true,
    component: AdminPanel
  },



];
