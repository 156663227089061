import React from 'react';
import {Col, Row} from 'shards-react';
import { Container } from "shards-react";

class HeaderBackendComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winkels:true,
            aboutUs: false,
            contact: false,
            faq: false,
            inschrijving: false,
            webshops:false
         
        };
    }


    toggleHamburger = () => {
      console.log('cliecked')
        document.getElementById('backend-menu').classList.remove('open')
    }

    onClickMenu(menuItem){
        if(menuItem === 'home'){
            this.setState({
                winkels:true,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }else if(menuItem=== 'deelnemers'){
            this.setState({
                winkels:false,
                aboutUs: true,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(menuItem=== 'recepten'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: true,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(menuItem === 'aan-het-woord'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: true,
                inschrijving: false,
                webshops:false
            })
        }
        else if(menuItem === 'inschrijving'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: true,
                webshops:false
            })
        }
        else if(menuItem === 'webshops'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:true
            })
        }
    }

    componentDidMount(){
        if(localStorage.getItem('menuActive') === 'home'){
            this.setState({
                winkels:true,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }else if(localStorage.getItem('menuActive') === 'deelnemers'){
            this.setState({
                winkels:false,
                aboutUs: true,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'recepten'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: true,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'aan-het-woord'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: true,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'inschrijving'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: true,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'webshops'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:true
            })
        }
    }

    render() {

        return (
            <Container fluid>

                <Row className="mobile-menu mobile-menu-backend">
                    <Col sm={12}>
                      
                        <button className="ham" onClick={() => this.toggleHamburger()}></button>
                        
                    </Col>


        </Row>

        </Container>
        );
    }
}

export default HeaderBackendComponent;
