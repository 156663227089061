import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "../src/assets/chashierr.css";
import "../src/assets/desktop-css.css";
import "../src/assets/notebook-css.css";
import "../src/assets/tablet-css.css";
import "../src/assets/mobile-css.css";
import LoginScreen from "./views/LoginScreen";
import PrivateRoute from "./privateRoute";
import SignOnView from "./views/Authentication/SignOnView";
import SignOnCompleteView from "./views/Authentication/SignOnCompleteView";
import AdminSignUp from "./views/Admin/AdminSignUpView";
import FrontendHome from "./views/Frontend/FrontendHome";
import 'mapbox-gl/dist/mapbox-gl.css';
import ParticipantView from "./views/Frontend/ParticipantView";
import ReceptsView from "./views/Frontend/ReceptsView";
import ReceptDetailsIndex from "./components/frontend/receptsDetailsComponent";
import ReceptsDetailView from "./views/Frontend/ReceptsDetailView";
import BlogsDetailView from "./views/Frontend/BlogsDetailView";
import ParticipantDetailsView from "./views/Frontend/ParticipantDetailsView";
import ParticipantNewView from "./views/Frontend/ParticipantNewView";
import HomeBlogView from "./views/Frontend/HomeBlogView";
import ShopsView from "./views/Frontend/ShopsView";
import ParticipantPostalView from "./views/Frontend/ParticipantPostalView";
import CarousselView from "./views/Frontend/CarousselComponentView";
import CheckoutView from "./views/Frontend/CheckoutView";
import CheckPaymentView from "./views/Frontend/CheckPaymentView";
import { Link } from "react-router-dom";
import ThankYouView from "./views/Frontend/ThankYouView";
import BaekvoitureView from "./views/Frontend/BaekVoitureView";
import BeekerOndernemerView from "./views/Frontend/BeekerOndernemerView";
import ThankYouBaekView from "./views/Frontend/ThankYouBaek";


export default () => (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <Switch>
    
    <div>
   <Route  path="/inloggen" component={LoginScreen}/>
   <Route exact path="/aanmelden" component={SignOnView}/>
   <Route exact path="/inschrijven" component={ParticipantNewView}/>
   <Route  path="/home" component={FrontendHome}/>
   <Route  path="/inschrijven-baekvoiture" component={BaekvoitureView}/>
   <Route  path="/inschrijven-beekerondernemer" component={BeekerOndernemerView}/>
   <Route exact path="/voorwoord" component={HomeBlogView}/>
   <Route exact path="/winkelwagen" component={ShopsView}/>
   <Route exact path="/afrekenen" component={CheckoutView}/>
   <Route exact path="/deelnemers" component={ParticipantView}/>
   <Route exact path="/deelnemers-postcode" component={ParticipantPostalView}/>
   <Route  path="/deelnemers/details" component={ParticipantDetailsView}/>
   <Route exact path="/recepten" component={ReceptsView}/>
   <Route exact path="/recepten/details" component={ReceptsDetailView}/>
   <Route exact path="/aan-het-woord" component={BlogsDetailView}/>
   <Route exact path="/slider" component={CarousselView}/>
   <Link to="/betaling-controleren/:id"/>
   <Route path="/betaling-controleren" component={CheckPaymentView}/>

   <Route path="/bedankt" component={ThankYouView}/>
   <Route path="/bedankt-baek" component={ThankYouBaekView}/>
  
   <Route exact path="/aanmelden-compleet" component={SignOnCompleteView}/>
      {routes.map((route, index) => {
        return (
          <PrivateRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          />
        );
      })}
    </div>
    </Switch>
  </Router>
);
