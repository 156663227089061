import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import HeaderBackendComponent from "../../components/Header/headerBackendMobile";
import OrdersIndex from "../../components/orders";
import BeekerOndernemerOrderComponent from "../../components/orders/BeekerondernemerOrder";



const BeekerOndernemerOrderView = () => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Inschrijvingen" subtitle="Beeker Ondernemer" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <HeaderBackendComponent />
    <BeekerOndernemerOrderComponent />
    
  </Container>
);

export default BeekerOndernemerOrderView;
