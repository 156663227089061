import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";

const SidebarNavLogout = ({ item }) => (


    
      
      
      
       
    
  <NavItem className=" logout-button">
    <NavLink tag={RouteNavLink} to={'/login'}>
      {item.htmlBefore && (
        <div
          className="d-inline-block item-icon-wrapper "
          dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
        />
      )}
      {item.title && <span>{item.title}</span>}
      {item.htmlAfter && (
        <div
          className="d-inline-block item-icon-wrapper "
          dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
        />
      )}

    </NavLink>
  </NavItem>
);

SidebarNavLogout.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavLogout;
