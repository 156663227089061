import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { set } from 'react-ga';
import { Satellite } from '@material-ui/icons';
import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer, Font } from '@react-pdf/renderer';
import GasparBold from '../../assets/fonts/Gaspar-Font/Gaspar_Bold.ttf'
import GasparRegular from '../../assets/fonts/Gaspar-Font/gaspar-regular.ttf'


Font.register({family:'Gaspbar', fonts:[
   {src: GasparBold, fontStyle:'bold'},
   {src: GasparRegular, fontStyle:'normal'},
]});
const styles = StyleSheet.create({
    page: {
      

      backgroundColor: 'white',

    },
    
    section: {
      paddingTop: 50,
      paddingBottom: 14,

    },sectionImage:{
        flex:1,

    }, Colum:{
        width :'40%',
        marginLeft:'5%',
        marginRight:'5%',
        padding:'7%',
        backgroundColor:'#245B31',
        borderRadius:20,
  
    },
 
    sectionImageAfter:{
        flexDirection: 'row',
        marginLeft:50,
        marginRight:50,
    },
    sectionImageAfterAfter:{
        flexDirection: 'row',
        marginLeft:50,
        marginRight:50,
        alignItems: 'center',
        position: 'absolute',
        bottom:50
    },

    sectionImageDefOne:{
width:'50%',
objectFit:'contain',
height:200,
alignItems:'center'
    },
    companyLogo:{
        width:'20%',
        objectFit:'contain',
        position:'absolute',
        height:60,
        marginTop:'5%'
    },
    sectionImageDefOneSingle:{
        width:'100%',
        objectFit:'contain',
        height:200,
        alignItems:'center'
    },
    sectionImageDefTwo:{
        width:'50%',

            },

            sectionImageDefThreeOne:{
                width:'33.3%',
  
                
            },
            sectionImageDefThreeTwo:{
                width:'33.3%',

            },sectionImageDefThreeThree:{
                width:'33.3%',
       
            },
    sectionTitle:{
 
    
        marginRight:'5%'
    },
    sectionDesc:{

      
    },
    sectionImageDef:{
        position:'absolute',
        width:600,
        height:350,
        objectFit:'cover',
        marginBottom:20,

    },
    row: {
        flexDirection: 'row',
        /*alignItems: 'top',*/
        margin:30,
        marginLeft:50,
        marginRight:50,
        marginTop:3,
        marginBottom:4,

    },
    abc: {
        width: '3%',
        objectFit:'contain',
        marginRight:'1%',
        border:'none',
        position:'absolute',
        
        top:0
    },
    description: {
        width: '20%',
        paddingLeft:'6%',
        fontSize:9,
        color:'red',
        top:5,
        fontFamily:'Gaspbar',
        fontStyle:'bold'
    },
    descriptionTitle:{
marginBottom:'2%'
    },
    xyz: {
        width: '70%',
        fontSize:9,
        marginTop:'0%',
        marginBottom:'2%',
        textAlign:'left',
        marginLeft:25,
        top:5,
        fontFamily:'Gaspbar',
        fontStyle:'normal'
    },
    title:{
        fontSize:18,
        position:'absolute',
        fontWeight:'800',
        top:0,
        textAlign:'left',
        marginBottom:'2%',
        marginLeft:'25%'
    },
    hrLine:{
        marginTop:0,
       
    },
    page: { padding: 60 },
    spaceBetween:{
        marginTop:330
    },
    companyViewLogoTest:{
        position:'absolute',
        width:150,
        height:150,
        alignItems:'flex-end',
        right:20,
        top:280,
        backgroundColor:'white',
        borderRadius:200
    },
    companyLogoTest:{
        width:70,
        height:70,
        marginTop:'10%',
        marginRight:'25%',
        alignItems:'center',
        alignContent:'center',
        objectFit:'contain'
    }

  });

  const items = [
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Naam van het bedrijf',desc:'De Jambrouwerij'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Adres bedrijf',desc:'Valmeerstraat 123'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Postcode en plaats',desc:'3770 Riemst'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Contactpersoon',desc:'Maarten Simonis'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Functie',desc:'Eigenaar'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Telefoon',desc:'+32 (0) 470-852110'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'E-mail',desc:'lekkerejams@dejambrouwerij.be'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Website',desc:'www.dejambrouwerij.be'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Sociale media',desc:'www.facebook.com/dejambrouwerij www.instagram.com/dejambrouwerij'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'MVO / Duurzaamheid',desc:'Wij werken daar waar mogelijk met lokale grondstoffen en met een team van jongeren met een beperking.'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Beschrijving van het bedrijf',desc:'Productie van ambachtelijke streekproducten en delicatessen.'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Product',desc:'Ambachtelijk vervaardigde jams, dressings, advocaat, koffielikeur, chocoladepasta, barbecuesaus en speculaaskoekenmix.'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Prijs',desc:'Tussen 4,30 en 5,95 euro.'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Periode',desc:'Geheel het jaar (40.000)'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Personeel',desc:'5'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Promotie',desc:'Website, sociale media, social media advertising.'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Bestellen',desc:'Consumenten bestellen via webwinkel, retail via de mail.'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Levering',desc:'Eigen stenen winkel, webwinkel en distributie aan retail.'},
      {icon:require("../../assets/images/streekproductengids/icons/group55.png"),title:'Extra informatie',desc:'Wij gebruiken meer fruit en minder suiker in onze jams.'},
    ]

  const MyDocument = (props) => (
    <Document >
      <Page size="A4"  wrap   style={styles.section}>
  

      <Image  style={styles.sectionImageDef} src={require("../../assets/images/streekproductengids/dummy-image.jpg")}/>
 
      <View style={styles.spaceBetween}></View>
        {items.map((item)=>(
        <View >
            <View style={styles.row} >
            <Image style={styles.abc} src={item.icon}/>
            <Text style={styles.description}>{props.data}</Text>    
       <Text style={styles.xyz}>{item.desc}</Text>
   </View>
        <View style={[styles.row, styles.hrLine]}>
            <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'40%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'60%',marginTop:'0%',flexDirection: 'row',}}/>
</View>
</View>
        ))}

        <View style={styles.sectionImageAfter}>
            <Image style={styles.sectionImageDefOne} src={require("../../assets/images/streekproductengids/left.jpg")}/>
            <Image style={styles.sectionImageDefTwo} src={require("../../assets/images/streekproductengids/right-2.jpg")}/>
            
        </View>

        <View style={styles.sectionImageAfterAfter}>
            <Image style={styles.sectionImageDefThreeOne} src={require("../../assets/images/streekproductengids/left-1.jpg")}/>
            <Image style={styles.sectionImageDefThreeTwo} src={require("../../assets/images/streekproductengids/middle.jpg")}/>
            <Image style={styles.sectionImageDefThreeThree} src={require("../../assets/images/streekproductengids/right-1.jpg")}/>
            
        </View>
   
        
      </Page>
     
      
    </Document>
  );
  
class AdminPanelIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products:[],
            categories:[],
            selectedProduct:[],
            isProductSelected:false,
            clientUUID:'-ohlnfwebk',
            city:'',
            almanakItems:[],
            imagesPrimaire:[],
            secundareImages:[],
            companyImages:[]
        };
    }

    
getUrl(company,name,id){


    Firebase.storage().ref('/Streekproductengids/images/'+company+'/'+name).getDownloadURL().then((url) => {
 
            

            let newObject ={id: id,url:url}

            this.setState(prevState => ({
              
                imagesPrimaire: [...prevState.imagesPrimaire, newObject]

            }));
      
      })


}

getCompanyImage(company,name,id){


    Firebase.storage().ref('/Streekproductengids/images/'+company+'/'+name).getDownloadURL().then((url) => {
 
            

            let newObject ={id: id,url:url}

            this.setState(prevState => ({
              
                companyImages: [...prevState.companyImages, newObject]

            }));
      
      })


}

getUrlSecundaireOne(company,id){
    let ref = Firebase.database().ref('/streekproducten/'+id+'/secundaireImages/');
    ref.once('value' , snapshot => {
       

       snapshot.val();
        snapshot.forEach((childSnap) => {
            let stateFac = childSnap.val();

            if(stateFac.name){
            let imageName = stateFac.name.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2');
            Firebase.storage().ref('/Streekproductengids/images/'+company+'/'+imageName).getDownloadURL().then((url) => {
 
        

                let newObject ={id: id,url:url}
                console.log(url)
                this.setState(prevState => ({
                  
                    secundareImages: [...prevState.secundareImages, newObject]
    
                }));
          
          })}else{
              console.log('no-image')
          }
           

        })
    });

   


}


    componentDidMount() {
 
        let ref = Firebase.database().ref('/streekproducten/');
        ref.once('value' , snapshot => {
           
   
           snapshot.val();
            snapshot.forEach((childSnap) => {
                let stateFac = childSnap.val();

                
           
         this.getUrl(stateFac.companyName,stateFac.imagesPrimaireName.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2'),stateFac.id)
         this.getUrlSecundaireOne(stateFac.companyName,stateFac.id);
         this.getCompanyImage(stateFac.companyName,stateFac.imagesCompanyName.replace(/^([^.]+)$|(\.[^.]+)$/i, '$1' + "_1920x1080" + '$2'),stateFac.id);
                this.setState(prevState => ({
                    almanakItems: [...prevState.almanakItems, stateFac],
                 

                }));

            })
        });

        

        /*let almanakPriImage = Firebase.database().ref('/streekproducten/');
        almanakPriImage.on('value' , snapshot => {
            this.setState({imagesPrimaire:[]})
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

         
            Firebase.storage().ref('/Streekproductengids/images/AbbGrowers BV/9PreJXK2yZtdblauwe bessen 1_1920x1080.jpg').getDownloadURL().then((url) => {

                let newInvoice ={ url: url};

                this.setState(prevState => ({
                    imagesPrimaire: [...prevState.imagesPrimaire, newInvoice]
                }))

              
              })
      

       

             
            
               
            });

        })*/
    
      



setTimeout(()=>this.setState({loadingDone:true}),10000)
        
    }



    

    changeActive(product){

        for(let k = 0; k <this.state.products.length; k++){
                this.state.products[k].checked = false;
                this.state.products[k].active = 'not-active';
                
                this.setState({
                    products: this.state.products,
                    selectedProduct: [],
                    isProductSelected:false
                })
               
             
        }

        for(let i = 0; i < this.state.products.length; i++){
            if(this.state.products[i].id === product.id && this.state.products[i].checked === false){
                this.state.products[i].active = 'active';
                this.state.products[i].checked = true;
                this.setState({
                    products: this.state.products,
                    selectedProduct: this.state.products[i],
                    isProductSelected:true
                })
            }else if(this.state.products[i].id === product.id && this.state.products[i].checked === true){
                this.state.products[i].active = 'not-active';
                this.state.products[i].checked = false;
                this.setState({
                    products: this.state.products,
                })
            }
        }


      
     
        
       /* this.setState({
            products:this.state.products.filter(item => item.id !== product.id),
        })

        let newProduct = {id:product.id,name:product.name, status:product.status,stock: product.stock,category:product.category,active:'active'}
        this.setState(prevState =>({
            products: [...prevState.products,newProduct]
        }))*/
    }


    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                    {this.state.isProductSelected ?
                    <div>
                    <Link to={{pathname: `/producten/aanpassen`,state: {data: this.state.selectedProduct}}} >Product bewerken</Link>
                    <Button className="delete-button" onClick={() => this.deleteProduct()}>Verwijder product</Button></div>:null}
                    <Link className="align-button-right" to={{pathname: `/producten/toevoegen`}} >Product toevoegen</Link>
                </Col>
                {this.state.loadingDone ?
                <Col sm={12}  className="pdfviewer">
                 
                
                <PDFViewer size="a4">
                <Document >
                {this.state.almanakItems.filter(item => item.category == 'Zuivel, kaas & eieren').sort((a, b) =>{
    if(a.companyName < b.companyName) { return -1; }
    if(a.companyName > b.companyName) { return 1; }
    return 0;
}).map((item)=>(
      <Page size="A4"  wrap   style={styles.section}>



{
this.state.imagesPrimaire.filter(image => item.id == image.id).map((image)=>(
    image.id?
      <Image  style={styles.sectionImageDef} src={image.url}/>
      :null
))}
{
this.state.companyImages.filter(image => item.id == image.id).map((image)=>(
    image.id?
<View style={styles.companyViewLogoTest}>

      <Image  style={styles.companyLogoTest} src={image.url}/>

</View>
      :null
      ))}
 
      <View style={styles.spaceBetween}></View>
    
        <View >
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/bedrijfsnaam.png")}/>
                <Text style={[styles.description,styles.descriptionTitle]}>Naam van het bedrijf</Text>    
                <Text style={[styles.xyz,styles.title]}>{item.companyName}</Text>
            </View>
   
        <View style={[styles.row, styles.hrLine]}>
                <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>

       <View >
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/adrespostcode.png")}/>
                <Text style={styles.description}>Adres bedrijf</Text>    
                <Text style={styles.xyz}>{item.companyAdres}</Text>
            </View>
   
        <View style={[styles.row, styles.hrLine]}>
        <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>


        <View >
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/postcode.png")}/>
                <Text style={styles.description}>Postcode en plaats</Text>    
                <Text style={styles.xyz}>{item.companyPostal}</Text>
            </View>
   
        <View style={[styles.row, styles.hrLine]}>
        <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>

        <View >
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/contactpersoon.png")}/>
                <Text style={styles.description}>Contactpersoon</Text>    
                <Text style={styles.xyz}>{item.companyContact}</Text>
            </View>
   
        <View style={[styles.row, styles.hrLine]}>
        <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>
        {item.companyFunction ? 
        <View >
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/functie.png")}/>
                <Text style={styles.description}>Functie</Text>    
                <Text style={styles.xyz}>{item.companyFunction}</Text>
            </View>
   
        <View style={[styles.row, styles.hrLine]}>
        <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>
        :null}

        <View >
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/telefoon.png")}/>
                <Text style={styles.description}>Telefoon</Text>    
                <Text style={styles.xyz}>{item.companyPhone}</Text>
            </View>
   
        <View style={[styles.row, styles.hrLine]}>
        <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>

        <View >
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/email.png")}/>
                <Text style={styles.description}>E-mail</Text>    
                <Text style={styles.xyz}>{item.companyMail}</Text>
            </View>
   
        <View style={[styles.row, styles.hrLine]}>
        <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>

        {item.companySite ? 
        <View>
  
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/website.png")}/>
                <Text style={styles.description}>Website</Text>    
                <Text style={styles.xyz}>{item.companySite}</Text>
            </View>
     
   
        <View style={[styles.row, styles.hrLine]}>
        <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>       : null}

        {item.companyFb || item.companyLi || item.companyIg ? 
        <View>
  
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/socialmedia.png")}/>
                <Text style={styles.description}>Social Media</Text>    
                <Text style={styles.xyz}>{item.companyFb}{item.companyFb && item.companyIg ? ', '+item.companyIg : item.companyIg} {item.companyFb && item.companyLi || item.companyIg && item.companyLi ? ', '+item.companyLi : item.companyLi} </Text>
            </View>
     
   
        <View style={[styles.row, styles.hrLine]}>
        <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>       : null}

      
        {item.mvo ? 
        <View break={item.mvo.length > 400 ? true:false}  >
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/mvoduurzaamheid.png")}/>
                <Text style={styles.description}>MVO / Duurzaamheid</Text>    
                <Text style={styles.xyz}>{item.mvo}</Text>
            </View>
   
        <View style={[styles.row, styles.hrLine]}>
        <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>
        :null}


       
     {item.desc? 
        <View  break={item.mvo.length < 400 ? true:false} >
            <View style={styles.row} >
                <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/beschrijving.png")}/>
               
                <Text style={styles.description}>Het bedrijf</Text>    
                <Text style={styles.xyz}>{item.desc}</Text>
            </View>
   
        <View style={[styles.row, styles.hrLine]}>
        <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
            </View>
        </View>:null}
         
        {item.product ? 
  <View >
      <View style={styles.row} >
          <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/product.png")}/>
          <Text style={styles.description}>Product</Text>    
          <Text style={styles.xyz}>{item.product}</Text>
      </View>

  <View style={[styles.row, styles.hrLine]}>
  <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
      </View>
  </View>
:null}

{item.price ? 
  <View >
      <View style={styles.row} >
          <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/prijs.png")}/>
          <Text style={styles.description}>Prijs</Text>    
          <Text style={styles.xyz}>{item.price}</Text>
      </View>

  <View style={[styles.row, styles.hrLine]}>
  <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
      </View>
  </View>
:null}
{item.period ? 
  <View >
      <View style={styles.row} >
          <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/periode.png")}/>
          <Text style={styles.description}>Periode</Text>    
          <Text style={styles.xyz}>{item.period}</Text>
      </View>

  <View style={[styles.row, styles.hrLine]}>
  <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
      </View>
  </View>
:null}
{item.employees ? 
  <View >
      <View style={styles.row} >
          <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/personeel.png")}/>
          <Text style={styles.description}>Personeel</Text>    
          <Text style={styles.xyz}>{item.employees}</Text>
      </View>

  <View style={[styles.row, styles.hrLine]}>
  <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
      </View>
  </View>
:null}
{item.promo ? 
  <View >
      <View style={styles.row} >
          <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/promotie.png")}/>
          <Text style={styles.description}>Promotie</Text>    
          <Text style={styles.xyz}>{item.promo}</Text>
      </View>

  <View style={[styles.row, styles.hrLine]}>
  <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
      </View>
  </View>
  :null}

{item.order ? 
  <View >
      <View style={styles.row} >
          <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/bestellen.png")}/>
          <Text style={styles.description}>Bestellen</Text>    
          <Text style={styles.xyz}>{item.order}</Text>
      </View>

  <View style={[styles.row, styles.hrLine]}>
  <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
      </View>
  </View>
:null}

    
{item.delivery ? 
  <View >
      <View style={styles.row} >
          <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/levering.png")}/>
          <Text style={styles.description}>Levering</Text>    
          <Text style={styles.xyz}>{item.delivery}</Text>
      </View>

  <View style={[styles.row, styles.hrLine]}>
  <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
      </View>
  </View>
:null}
{item.extraInfo ? 
  <View >
      <View style={styles.row} >
          <Image style={styles.abc} src={require("../../assets/images/streekproductengids/icons/extrainfo.png")}/>
          <Text style={styles.description}>Extra informatie</Text>    
          <Text style={styles.xyz}>{item.extraInfo}</Text>
      </View>

  <View style={[styles.row, styles.hrLine]}>
  <View style={{borderBottomColor: 'black',borderBottomWidth: 1,width:'25%',marginTop:'0%',flexDirection: 'row',}}/>
                <View style={{borderBottomColor: 'red',borderBottomWidth: 1,width:'75%',marginTop:'0%',flexDirection: 'row',}}/>
      </View>
  </View>
:null}





        <View style={styles.sectionImageAfter}>
       { this.state.secundareImages.filter(image => item.id == image.id).slice(0,2).map((image,idx, arr)=>(
           arr.length  > 1?
           
             <Image style={styles.sectionImageDefOne} src={image.url}/>
        : <Image style={styles.sectionImageDefOneSingle} src={image.url}/>
       
       ))}
         
            
           {/*} <Image style={styles.sectionImageDefTwo} src={require("../../assets/images/streekproductengids/right-2.jpg")}/>*/}
            
        </View>


   
        
      </Page>
        ))}
     
      
    </Document>


  </PDFViewer>
   
</Col>:null}
   
            </Row>
            </div>



        );
    }
};

export default AdminPanelIndex;