import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, string } from "shards-react";
import Firebase from 'firebase';

import { Container } from "shards-react";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import Select from 'react-select'
import {
   isSafari, isChrome
  } from "react-device-detect";


class CheckoutComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            productAmount:1,
            email:'',
            firstName:'',
            lastName:'',
            phone:'',
            email:'',
            companyFirstname:'',
            companyLastname:'',
            companyZip:'',
            companyAdres:'',
            companyBTW:'',
            companyNumber:'',
            companyEmail:'',
            companyPhone:'',
            comapnyCity:'',
            company:false,
            bagItems:[],
            productImages:[],
            allTotals:0,
            particulier: true,
            zakelijk:false,
            companyName:'',
            pickUp:false,
            deliver: false,
            hasNoPerBakfiets:[],
            hasPerBakfiets:[],
            fcmtokens:[],
            companyImages:[],
            country:'selected',
            delivery:'Bezorgen',
            deliveryAdded:false,
            orders:[],
            orderId:'',
            disabledDates:[],
            selectedDay:'',
            timeslots:["17:00","17:15","17:30","17:45","18:00","18:15","18:30","18:45","19:00","19:15","19:30","19:30","19:45","20:00","20:15","20:30","20:45","21:00"],
            timeslotsReset:["17:00","17:15","17:30","17:45","18:00","18:15","18:30","18:45","19:00","19:15","19:30","19:30","19:45","20:00","20:15","20:30","20:45","21:00"],
             timeslotsReset:["17:00","17:15","17:30","17:45","18:00","18:15","18:30","18:45","19:00","19:15","19:30","19:30","19:45","20:00","20:15","20:30","20:45","21:00"],
            timeslotsDisabled:[],
            filteredTimes:[],
            dateSet:false,
            timeslot:'',
            deliveryCost:0,
            postalSet:false,
            title:''

        };
    }
    

    products = [];


    checkProducts(){
        this.products = JSON.parse(localStorage.getItem('shoppingBag')) ;
     
        for(let i = 0; i< this.products.length; i++){

console.log(this.products[i])
           let products = Firebase.database().ref('/clients/-ljcbwenpidwdkenw/products/'+this.products[i].id);
         products.on('value' , snapshot => {
               let state = snapshot.val();

               


               let addedProduct = {name:state.name,price:state.price,amount:this.products[i].amount, id:this.products[i].id,sidedish:this.products[i].sideDish,uniqueId:this.products[i].uniqueId}
             //  let totalAmount = parseFloat() *this.products[i].amount;

         
              
               this.setState(prevState =>({
                   bagItems: [...prevState.bagItems,addedProduct],
               }))
              this.calculateTotel(state.price)
     
               
              
              
           })
           
           
       }

            


          
    }
    totalPrice = 0;
    calculateTotel(newPayment){
  

        let payment=  this.state.allTotals+ newPayment;
console.log(this.state.allTotals)
        console.log(payment)

        let totalNew =this.totalPrice + newPayment;
        console.log(totalNew)

       this.setState({
            allTotals: totalNew
        })

        this.totalPrice = totalNew;

       

        localStorage.setItem('total',(this.totalPrice*100).toFixed(0))

        

    }

     calculateTotelMin(newPayment){

        this.setState({
             allTotals:this.state.allTotals- newPayment
         })

     
 
         
 
     }

     disabledHour = [];

     checkTime(day){

        let myDate = new Date();
        console.log(myDate.getHours())

      
     
  
        for(let i=0; i < 61; i++){
            
            let addminutes = (i) *60*1000;
            if (isSafari) {
            if(new Date(myDate.getTime()+ addminutes).getMinutes() == "0" && new Date(day).getDate() == new Date().getDate()){
                this.disabledHour.push((new Date(myDate.getTime()+ addminutes).getHours()+':0'+new Date(myDate.getTime()+ addminutes).getMinutes()));
                let newSlots = this.state.timeslots.filter(item => !this.disabledHour.includes(item) );
    console.log(newSlots)
                console.log(newSlots)
                console.log(newSlots)
                this.setState({
                    timeslots:newSlots,
                    timeslotsReset:newSlots,
                
                })
    
            }else if(new Date(day).getDate() == new Date().getDate()){
                this.disabledHour.push((new Date(myDate.getTime()+ addminutes).getHours()+':'+new Date(myDate.getTime()+ addminutes).getMinutes()));
                let newSlots = this.state.timeslots.filter(item => !this.disabledHour.includes(item) );
    
                console.log(newSlots)
                console.log(newSlots)
                this.setState({
                    timeslots:newSlots,
                    timeslotsReset:newSlots
                })
            }}else{
                if(new Date(myDate.getTime()+ addminutes).getMinutes() == "0" && new Date(day).getDate() == new Date().getDate()){
                    this.disabledHour.push((new Date(myDate.getTime()+ addminutes).getHours()+':0'+new Date(myDate.getTime()+ addminutes).getMinutes()));
                    let newSlots = this.state.timeslots.filter(item => !this.disabledHour.includes(item) );
        
                    console.log(newSlots)
                    console.log(newSlots)
                    this.setState({
                        timeslots:newSlots,
                        timeslotsReset:newSlots,
                    
                    })
        
                }else if(new Date(day).getDate() == new Date().getDate()){
                    this.disabledHour.push((new Date(myDate.getTime()+ addminutes).getHours()+':'+new Date(myDate.getTime()+ addminutes).getMinutes()));
                    let newSlots = this.state.timeslots.filter(item => !this.disabledHour.includes(item) );
        
                    console.log(newSlots)
                    console.log(newSlots)
                    this.setState({
                        timeslots:newSlots,
                        timeslotsReset:newSlots
                    })
                }
            }


                //let time =new Date(myDate.getTime()+ addminutes).getHours()+':'+new Date(myDate.getTime()+ addminutes).getMinutes();
              //  let newSlots = this.state.timeslots.filter(item => item != time );
              //  console.log(newSlots)
            }
            //console.log(new Date(myDate.getTime()+ addminutes).getHours()+':'+new Date(myDate.getTime()+ addminutes).getMinutes())
          
        
     }
     
    


    componentDidMount= async() =>{


       
       

        window.scrollTo(0, 0);
        if(localStorage.getItem('shoppingBag') != null){
            await this.checkProducts();
 
                  
            
        }

        //his.calcDelivery();
        

        let ref = Firebase.database().ref('/clients/-ljcbwenpidwdkenw/payments');
        ref.on('value' , snapshot => {
            this.setState({orders:[]})
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

             this.setState(prevState => ({
                orders: [...prevState.orders, state]
                    })) 
                })})

        let timeslots = Firebase.database().ref('/clients/-ljcbwenpidwdkenw/timeslots');
        timeslots.on('value' , snapshot => {
            this.setState({timeslotsDisabled:[]})
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            
          
            this.setState(prevState => ({
                timeslotsDisabled: [...prevState.timeslotsDisabled, state]
                })) 
        })})


     

     //  this.fetchStripe()


    }

    changePickUp(){
        if(this.state.pickUp === true){
            this.setState({
                pickUp: false,
                deliver: false
            })
            localStorage.setItem('pickup',true)
        }else if( this.state.pickUp === false){
            this.setState({
                pickUp: true,
                deliver:false
            })
            localStorage.setItem('pickup',false)
        }

    }

    changeDeliver(){
        if(this.state.deliver === true){
            this.setState({
                pickUp: false,
                deliver: false
            })
            localStorage.setItem('deliver',true)

        }else if( this.state.deliver === false){
            this.setState({
                pickUp: false,
                deliver:true
            })
            localStorage.setItem('deliver',false)
        }


    }


  /*  fetchStripe(){
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','http://localhost:3000');
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
       fetch("https://us-central1-web-app-bbo-prod.cloudfunctions.net/helloCors", {
      
            method: 'POST',
        headers: headers,
        body: JSON.stringify({amount:2330})
          }).then(function(response) {
              console.log('here')
              console.log(response)
            return response.json();
          }).then(function(responseJson) {
  
            var clientSecret = responseJson.client_secret;
            console.log('secret is:'+clientSecret)

            // Call stripe.confirmIdealPayment() with the client secret.
          });

         // localStorage.setItem('amount', (this.state.allTotals.toFixed(2)*100))
    }*/


changeName(event){
    this.setState({
        firstName:event}
        ) ;

    localStorage.setItem('name',event)
    localStorage.setItem('amount', Number(this.state.allTotals.toFixed(2)*100))
    localStorage.setItem('tokens',JSON.stringify(this.state.fcmtokens))
}

changePhone(event){
    this.setState({
        phone:event}
        ) ;

    localStorage.setItem('phone',event)

}

changeMail(event){
    console.log('hello')
    this.calcDelivery();
    this.setState({
        email:event}
        ) ;

    localStorage.setItem('mail',event)

}

checkCompany(){
    if(this.state.zakelijk === true){
        this.setState({
            zakelijk: false
        })
    }else if(this.state.zakelijk === false){
        this.setState({
            zakelijk:true
        })
    }
}
    

calcDelivery(){

    if(this.state.allTotals > 25){
       
        this.setState({
            delivery: 'Bezorgen',
            deliveryAdded:true,
            deliveryCost: 0,

        })
    }else if(this.state.allTotals  < 25){
        let addDeliveryCost = this.totalPrice + 5;
        this.setState({
            delivery: 'Bezorgen',
            deliveryAdded:true,
            allTotals: addDeliveryCost,
            deliveryCost: 5,
            
        })
    }
}
orderId = '';
generateOrderId(){


    let lengthIs = 0;

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();

   


    return 'J'+year+month+(this.state.orders.length+1);
    
}

setName(event){
    this.setState({companyName:event})
    this.orderId = this.generateOrderId();
    console.log()

    console.log(parseFloat(this.state.allTotals).toFixed(2).toString())

   console.log( this.orderId)
}

 timslots =  ["17:00","17:15","17:30","17:45","18:00","18:15","18:30","18:45","19:00"];
 disabledTime = []

 


async handleDayClick(day) {

   await this.checkTime(day);

   console.log(day)
 
   this.setState({
    dateSet:false,

   }) 
   this.setState({
    timeslots: this.state.timeslotsReset
})


    for(let i = 0; i< this.state.timeslotsDisabled.length; i++){
        //console.log(new Date(this.state.timeslotsDisabled[i].date.replace(/-/g, "/")))      
        if (isSafari) {
        
        if(new Date(this.state.timeslotsDisabled[i].date.replace(/-/g, "/")).getFullYear() == day.getFullYear() && new Date(this.state.timeslotsDisabled[i].date.replace(/-/g, "/")).getMonth() == day.getMonth() && new Date(this.state.timeslotsDisabled[i].date.replace(/-/g, "/")).getDate() == day.getDate()){

            this.disabledTime.push(this.state.timeslotsDisabled[i].time);
                let newSlots = this.state.timeslots.filter(item => !this.disabledTime.includes(item) );
                console.log(newSlots)
                this.setState({
                    timeslots: newSlots,
                    dateSet:true
                })
            }else{
                console.log('else');
                this.setState({
                   
                    dateSet:true
                })
            }}else{
                if(new Date(this.state.timeslotsDisabled[i].date).getFullYear() == day.getFullYear() && new Date(this.state.timeslotsDisabled[i].date).getMonth() == day.getMonth() && new Date(this.state.timeslotsDisabled[i].date).getDate() == day.getDate()){

                    this.disabledTime.push(this.state.timeslotsDisabled[i].time);
                        let newSlots = this.state.timeslots.filter(item => !this.disabledTime.includes(item) );
                        console.log(newSlots)
                        this.setState({
                            timeslots: newSlots,
                            dateSet:true
                        })
                    }else{
                        console.log('else');
                        this.setState({
                           
                            dateSet:true
                        })
                    }
            }
        }   


    this.setState({
      selectedDay: day,
    });

}

calcTimeslot(event){
    console.log(event)
    this.setState({
        timeslot: event
    })
}

placePayment(){
    if(this.state.bagItems.length > 0 ){

    Firebase.database().ref('/clients/-ljcbwenpidwdkenw/payments/'+this.orderId).update({
        items: this.state.bagItems,
        date:this.state.selectedDay,
        delivery:"Bezorgen",
        id:this.orderId,
        orderCountry:this.state.country,
        orderCity:this.state.comapnyCity,
        orderId: this.orderId,
        orderMail :this.state.email,
        orderName: this.state.companyName,
        orderNumber: this.state.companyNumber,
        orderPhone:this.state.phone,
        orderPostal:this.state.companyZip,
        orderPrice:this.state.allTotals,
        orderStreet:this.state.companyAdres,
        paymentId:'',
        newOrder:true,

        status:'unpaid',
        timeslot:this.state.timeslot,
        }).then(()=>{            
            document.getElementById("hook-form").submit();
    })
}else{
   console.log('fout')
}
    console.log(this.state.bagItems)
   // document.getElementById("hook-form").submit();
}

    
conditions = ["6217", "6218", "6219", "6223","3620","3621","3630","3690","3740"];

    render() {
        const { selectedDay } = this.state;
        const FORMAT = 'dd/MM/yyyy';
        return (
           

                    

                <Col sm={12}>
                    <Container>
                        <Row>
                            <Col sm={12} className="breadcrumbs-col">
                               <span className="breadcrumbs"> <Link to={'../'}>{this.state.title}</Link> / <Link to={'../winkelwagen'}>Winkelmand</Link> / <Link>Afrekenen</Link> </span>
                              
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid>
                        <hr  className="dotted-full-line"></hr>
                    </Container>
                    <Container>
                    
                        <Row >
                            <Col sm={6} className="checkout-detail">
                            
                            <span>
                                
                            <h2>Contact informatie</h2>
                    
<form method="post" action={"https://us-central1-cashierr-pixelpros.cloudfunctions.net/paymentJesse"} id="hook-form"> 
 
    <span className="input-full-width">
        <label>Je naam</label><br/>
        <input  required type="text" name="orderName" value={this.state.companyName} onChange={(event) => this.setName(event.target.value)}/>
    </span>
    <span className="input-full-width">
                                    <label>E-mail</label><br/>
                                    <input required type="text" name="orderMail" value={this.state.email} onChange={(event) => this.changeMail(event.target.value)} required/>
                                </span>
                                <span className="input-full-width">
                                    <label>Telefoonnummer</label><br/>
                                    <input required type="text" name="orderPhone" value={this.state.phone} onChange={(event) => this.changePhone(event.target.value)} />
                                </span>

    <span className="input-half-width">
        <label>Straatnaam</label><br/>
        <input required type="text" name="orderStreet" value={this.state.companyAdres} onChange={(event) => this.setState({companyAdres:event.target.value})} />
    </span>
    <span className="input-half-width">
        <label>Huisnummer</label><br/>
        <input required type="text" name="orderNumber" value={this.state.companyNumber} onChange={(event) => this.setState({companyNumber:event.target.value})} />
    </span>
    <span className="input-half-width">
        <label>Postcode</label><br/>
        <input required type="text" name="orderPostal" value={this.state.companyZip} onChange={(event) => this.setState({companyZip:event.target.value,postalSet:true})} />
    </span>
    <span className="input-half-width">
        <label>Woonplaats</label><br/>
        <input required type="text" name="orderCity" value={this.state.comapnyCity} onChange={(event) => this.setState({comapnyCity:event.target.value})} />
    </span>
    <span className="input-full-width">
        <label>Land</label><br/>
        <select required name="orderCountry" value={this.state.country} onChange={(event)=>this.setState({country:event.target.value})}>
            <option value="selected" disabled>Selecteer een land</option>
            <option value="België">België</option>
            <option value="Nederland">Nederland</option>
        </select>
    </span>
    {/*<span className="input-full-width">
        <label>Afhalen of bezorgen</label><br/>
        <select required name="delivery" value={this.state.delivery} onChange={(event)=>this.calcDelivery(event.target.value)}>
            <option value="selected" disabled>Maak een keuze</option>
           {this.conditions.some(el =>this.state.companyZip.includes(el)) ? <option value="Bezorgen">Bezorgen</option> :null } 
            <option value="Afhalen">Afhalen</option>
        </select>
        </span>*/}

        {this.conditions.some(el =>this.state.companyZip.includes(el)) ? 
        <span>
    <span className="input-full-width">
        <label>Datum afhalen</label><br/>
        
        <DayPicker
                               selectedDays={this.state.selectedDay}
                               onDayClick={(event)=>this.handleDayClick(event)}

      formatDate={this.formatDate}
      format={FORMAT}
      parseDate={this.parseDate}
      disabledDays={[{daysOfWeek: [1,2,3]}]}
      required
      onDayChange={(event)=>this.handleDayChangeEnd(event)} 
    />
   
     <input required type="text" hidden name="date" value={this.state.selectedDay}/>
    </span>

{this.state.dateSet ? 
    <span className="input-full-width">
        <label>Tijdslot</label><br/>
        <select required name="timeslot" value={this.state.timeslot}  onChange={(event)=>this.calcTimeslot(event.target.value)} >
            <option value="selected" disabled>Maak een keuze</option>
            {this.state.timeslots.map((item,i)=>(
                      <option key={i} value={item} >{item}</option>
            ))}
        </select>
     
      
      
    </span>:null}

    <input type="text" hidden value={parseFloat(this.state.allTotals).toFixed(2).toString()} name="totalPrice" />
    <input type="text" hidden value={this.orderId} name="orderId" />
    </span>:
    this.state.postalSet ?
    <span className="postal-error"><p><br/><br/>We bezorgen helaas niet op uw postcode.<br/> We bezorgen in, Maastricht: 6217, 6218, 6219, 6223 en in Lanaken in: 3620, 3621, 3630, 3690, 3740</p></span>
:null}
</form><br/>
                       
                        </span>
                        
                            

                            
                            </Col>
                            <Col sm={6} className="checkout-detail">
                                <h2>Bestelling</h2>
                               <ul className="checkout-shoppinglist">
                                   {this.state.bagItems.map((item)=>(
                                        <li>
                                            <span className="product-title-shoppinglist-checkout">{item.name}</span><span className="product-amount-shoppinglist-checkout">{item.amount} x</span><span className="product-price-shoppinglist-checkout">€ {item.price.toFixed(2)}</span></li>
                                   ))}
                                  
                                   
                               </ul>
                               <hr/>
                               <br/>
                               {this.conditions.some(el =>this.state.companyZip.includes(el)) ? <span>
                               <p className="subtotal-css">Subtoaal<span className="checkout-shoppinglist-total"> € {this.state.allTotals.toFixed(2)}</span></p>
                               <p>Bezorgenkosten<span className="checkout-shoppinglist-total"> € {this.state.deliveryCost.toFixed(2)}</span></p>
                              
                               
                               <p><b>Totaal</b><span className="checkout-shoppinglist-total"><b>€ {this.state.allTotals.toFixed(2)}</b></span></p>
                              {/*} <Button className="pay-bill-button">Afrekenen</Button>*/}
                              <button className="pay-bill-button-checkout" /*type="submit" form="hook-form"*/ onClick={()=>this.placePayment()} >Naar betalen</button>
                              </span>:null}
                            
                            </Col>
                        </Row>

                        
                    </Container>
                </Col>
               
               
                
                    
   

            
       



        );
    }
};

export default CheckoutComponent;